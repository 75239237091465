import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  Filter,
  Plus,
  X,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from "lucide-react";
import TablePagination from "../../components/table_pagination";
import { useFormik } from "formik";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getAllUsers } from "../../network_calls/user_api";
import { getAllOrganizations } from "../../network_calls/organization_api";
import FilterActionsFooter from "../../components/filter_actions_footer";
import { sortAlphabetically } from "../../utils/helper";
import Multiselect from "multiselect-react-dropdown";
import MultiselectDropdown from "../../components/multi_select_dropdown";
import useAuthData from "../../hooks/use_auth_data";
import { ADMIN } from "../../utils/constants";
import UserTable from "../../components/user_table";

function roleDropdownOptions(role) {
  let options =
    role === ADMIN
      ? [
          { name: "Admin", id: "admin" },
          { name: "User", id: "user" },
          { name: "Organizer Admin", id: "organizer_admin" },
          { name: "Organizer Staff", id: "organizer_staff" },
        ]
      : [
          { name: "Organizer Admin", id: "organizer_admin" },
          { name: "Organizer Staff", id: "organizer_staff" },
        ];

  return options;
}

const UserList = () => {
  const navigate = useNavigate();
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const [filters, setFilters] = useState({
    role: [], // Used an Array to store multiple organization id's
    is_active: "",
    organization_id: [], // Used an Array to store multiple organization id's
    is_email_verified: "",
  });

  const [loginResult] = useAuthData();

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await getAllOrganizations({
          is_active: true,
        });
        const alphabeticallySortedResult = sortAlphabetically(response.result);
        setOrganizationList(alphabeticallySortedResult || []);
      } catch (error) {
        if (error.code !== 404) {
          toast.error("Failed to fetch organizations");
        }
      }
    };
    if (loginResult?.role === ADMIN) {
      fetchOrganizations();
    }
  }, [loginResult]);

  const handleFilter = async () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const handleSort = async (key) => {
    let newOrder = "asc";

    if (sortConfig.key === key) {
      if (sortConfig.order === "asc") {
        newOrder = "desc";
      } else if (sortConfig.order === "desc") {
        // Reset sorting
        key = "";
        newOrder = "";
      }
    }

    setSortConfig({
      key: key,
      order: newOrder,
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="inline ml-2 h-4 w-4" />;
    }
    return sortConfig.order === "asc" ? (
      <ArrowUp className="inline ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="inline ml-2 h-4 w-4" />
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getUserList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, itemsPerPage, searchTerm, filters, sortConfig]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleFilterChange = (filterData) => {
    setFilters(filterData);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleResetFilters = () => {
    formik.resetForm();
    handleFilterChange({
      role: [], // Reset `role` to initail value
      is_active: "",
      organization_id: [], // Reset `organization_id` to initail value
      is_email_verified: "",
    });
    handleFilter();
  };

  const formik = useFormik({
    initialValues: {
      user_role: [], // Used an Array to store multiple user_role's
      is_active: "",
      organization_id: [], // Used an Array to store multiple organization_id's
      is_email_verified: "",
    },
    validate: (values) => {
      const errors = {};
      return errors;
    },
    onSubmit: (values) => {
      handleFilterChange({
        role: values.user_role.join(","), // Used join method to convert to a comma separated string
        is_active: values.is_active,
        organization_id: values.organization_id.join(","), // Used join method to convert to a comma separated string
        is_email_verified: values.is_email_verified,
      });
      handleFilter();
    },
  });

  const getUserList = async () => {
    try {
      loader(true);
      const response = await getAllUsers({
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        role: filters.role,
        is_active: filters.is_active,
        organization_id: filters.organization_id,
        is_email_verified: filters.is_email_verified,
        sort_key: sortConfig.key,
        sort_order: sortConfig.order,
      });
      setTotalCount(response.pagination.total);
      setUserListData(response);
      toast.success("List fetched successfully");
    } catch (error) {
      if (error.code === 404) {
        setTotalCount(0);
        setUserListData([]);
      } else {
        // Show error toast for other errors
        toast.error(error.message || "Operation failed");
      }
    } finally {
      loader(false);
    }
  };

  const addUser = () => {
    navigate(ROUTES.USERS.CREATE);
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Users</h1>
          <p className="text-sm text-gray-500">Masters - Users</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          {/* <!---- Top bar section with search, filter, download, and add user buttons  ----> */}

          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleFilter}
                className="px-4 py-2 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 flex items-center gap-2 text-sm"
              >
                <Filter className="w-5 h-5" />
                Filter
              </button>
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => addUser()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
              >
                <Plus className="w-5 h-5" />
                Add User
              </button>
            </div>
          </div>

          {/* <!---- User table component with pagination component as a children prop ----> */}
          <UserTable
            userListData={userListData}
            onSort={handleSort}
            onGetSortIcon={getSortIcon}
          >
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={itemsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(newPageSize) => {
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          </UserTable>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/* <!-- Drawer Filter-->*/}
      {isFilterDrawerOpen ? (
        <>
          <div
            className="fixed top-0 left-0 z-50 w-full h-screen bg-black opacity-50"
            onClick={handleFilter}
          />
          <div
            className={`fixed top-0 right-0 z-50 w-full h-screen max-w-sm bg-white flex flex-col transition-transform ${
              isFilterDrawerOpen ? "translate-x-0" : "translate-x-full"
            }`}
            tabIndex="-1"
            aria-labelledby="drawer-label"
          >
            {/* Fixed Header */}
            <div className="px-4 py-3 border-b flex items-center justify-between">
              <h5 className="text-sm font-semibold text-gray-500 uppercase">
                Filter List
              </h5>
              <button
                onClick={handleFilter}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              >
                <X className="w-5 h-5" />
                <span className="sr-only">Close menu</span>
              </button>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col h-full"
            >
              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto p-4 mb-28">
                <div className="space-y-4">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Role
                    </label>
                    <MultiselectDropdown
                      formik={formik}
                      options={roleDropdownOptions(loginResult?.role)}
                      formikValue="user_role"
                      placeholder="Select role"
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Status
                    </label>
                    <select
                      name="is_active"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.is_active}
                    >
                      <option value="">All</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>

                  {loginResult?.role === ADMIN && (
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Organization
                      </label>
                      <Multiselect
                        name="organization_id"
                        options={organizationList} // list of options to display
                        displayValue="name" // the object `key` whose value we want to display
                        // `selectedValues` is the array of objects representing the selected options.
                        // Here we are filtering the `organizationList` to find the organizations whose IDs are in the formik state (`formik.organization_id`).
                        selectedValues={organizationList.filter((org) =>
                          formik.values.organization_id.includes(org.id)
                        )}
                        onSelect={(selectedList) => {
                          formik.setFieldValue(
                            "organization_id",
                            selectedList.map((org) => org.id)
                          );
                        }}
                        onRemove={(selectedList) => {
                          formik.setFieldValue(
                            "organization_id",
                            selectedList.map((org) => org.id)
                          );
                        }}
                        placeholder="All Organizations"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full"
                        style={{
                          chips: {}, // Clear styles for selected options (chips)
                          searchBox: {
                            padding: "0", // Remove padding for search box
                            width: "100%", // Make search box take full available width
                            color: "black", // Set the color of the placeholder text to black
                          }, // Clear styles for the search box
                          multiselectContainer: {
                            width: "100%",
                          }, // Clear styles for the outer container
                          optionContainer: {}, // Clear styles for the dropdown menu
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Email Verification Status
                    </label>
                    <select
                      name="is_email_verified"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.is_email_verified}
                    >
                      <option value="">All</option>
                      <option value="true">Verified</option>
                      <option value="false">Unverified</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Fixed Footer */}
              <div className="border-t p-4 bg-white">
                <FilterActionsFooter onReset={handleResetFilters} />
              </div>
            </form>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UserList;
