import axiosClient from "../utils/api_client";

export function getAllEventCategories(param) {
  return axiosClient.get("/event/categories", {
    params: param,
  });
}

export function getEventCategoryDetails(id) {
  return axiosClient.get(`/event/categories/${id}`);
}

export function updateEventCategoryDetails(id, data) {
  return axiosClient.put(`/event/categories/${id}`, data);
}

export function addEventCategory(data) {
  return axiosClient.post("/event/categories", data);
}

export function getAllGenres(param) {
  return axiosClient.get("/event/genres", {
    params: param,
  });
}

export function getGenreDetails(id) {
  return axiosClient.get(`/event/genres/${id}`);
}

export function addGenre(data) {
  return axiosClient.post("/event/genres", data);
}

export function updateGenreDetails(id, data) {
  return axiosClient.put(`/event/genres/${id}`, data);
}

export function getAllEvents(param) {
  return axiosClient.get("/events", {
    params: param,
  });
}

export function addEvent(formData) {
  return axiosClient.post("/events", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updateEventDetails(id, formData) {
  return axiosClient.put(`/events/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getEventDetails(id) {
  return axiosClient.get(`/events/${id}`);
}

export function getAllEventVenues(param) {
  return axiosClient.get("/event/venues", {
    params: param,
  });
}

export function getEventVenueDetails(id) {
  return axiosClient.get(`/event/venues/${id}`);
}

export function addEventVenue(data) {
  return axiosClient.post("/event/venues", data);
}

export function updateEventVenueDetails(id, data) {
  return axiosClient.put(`/event/venues/${id}`, data);
}

export function getAllTicketType(param) {
  return axiosClient.get("/event/ticket-types", {
    params: param,
  });
}

export function getTicketTypeDetails(id) {
  return axiosClient.get(`/event/ticket-types/${id}`);
}

export function addTicketType(data) {
  return axiosClient.post("/event/ticket-types", data);
}

export function updateTicketTypeDetails(id, data) {
  return axiosClient.put(`/event/ticket-types/${id}`, data);
}

export function getAllArtists(param) {
  return axiosClient.get("/event/artists", {
    params: param,
  });
}

export function getArtistDetails(id) {
  return axiosClient.get(`/event/artists/${id}`);
}

export function updateArtistDetails(id, data) {
  return axiosClient.put(`/event/artists/${id}`, data);
}

export function addArtist(data) {
  return axiosClient.post("/event/artists", data);
}
