const ROUTES = {
  // Auth
  LOGIN: "/login",

  // Dashboard
  DASHBOARD: "/",

  // Events
  EVENTS: {
    LIST: "/events",
    CREATE: "/events/create",
    VIEW: "/events/:id",
    EDIT: "/events/:id/edit",
    CATEGORIES: {
      LIST: "/events/categories",
      CREATE: "/events/categories/create",
      VIEW: "/events/categories/:id",
      EDIT: "/events/categories/:id/edit",
    },
    VENUES: {
      LIST: "/events/venues",
      CREATE: "/events/venues/create",
      VIEW: "/events/venues/:id",
      EDIT: "/events/venues/:id/edit",
    },
  },

  // Users
  USERS: {
    LIST: "/users",
    CREATE: "/users/create",
    VIEW: "/users/:id",
    EDIT: "/users/:id/edit",
  },

  // Organizations
  ORGANIZATIONS: {
    LIST: "/organizations",
    CREATE: "/organizations/create",
    VIEW: "/organizations/:id",
    EDIT: "/organizations/:id/edit",
    CONTRACTS: {
      LIST: "/organizations/contracts",
      CREATE: "/organizations/contracts/create",
      VIEW: "/organizations/contracts/:id",
    },
  },

  // Artists
  ARTISTS: {
    LIST: "/artists",
    CREATE: "/artists/create",
    VIEW: "/artists/:id",
    EDIT: "/artists/:id/edit",
  },

  // Genres
  GENRES: {
    LIST: "/genres",
    CREATE: "/genres/create",
    VIEW: "/genres/:id",
    EDIT: "/genres/:id/edit",
  },

  // Ticket Types
  TICKET_TYPES: {
    LIST: "/tickets/types",
    CREATE: "/tickets/types/create",
    VIEW: "/tickets/types/:id",
    EDIT: "/tickets/types/:id/edit",
  },

  // Notifications
  NOTIFICATIONS: {
    LIST: "/notifications",
  },

  // Profile
  PROFILE: {
    VIEW: "/profile",
    EDIT: "/profile/:id/edit",
  },
};

export default ROUTES;
