import { Eye, MoreVertical, Pencil, Trash2 } from "lucide-react";
import DateFormatter from "../utils/date_formatter";
import DeleteModal from "./delete_modal";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../config/routes";

function UserTable({ userListData, onSort, onGetSortIcon, children }) {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleDeleteClick = (user) => {
    setDeletingUser(user);
  };

  const handleCloseModal = () => {
    setDeletingUser(null);
  };

  const confirmDelete = async () => {};

  const viewUserDetails = (user) => {
    navigate(ROUTES.USERS.VIEW.replace(":id", user.id));
  };

  const editUserDetails = (user) => {
    navigate(ROUTES.USERS.EDIT.replace(":id", user.id));
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-y border-gray-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                S.NO.
              </th>
              <th
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                onClick={() => onSort?.("firstname")}
              >
                <div className="flex items-center">
                  NAME
                  {onGetSortIcon?.("firstname")}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                PHONE NUMBER
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                EMAIL
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                ROLE
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                STATUS
              </th>
              <th
                className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                onClick={() => onSort?.("created_at")}
              >
                <div className="flex items-center">
                  CREATED AT
                  {onGetSortIcon?.("created_at")}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                ACTIONS
              </th>
            </tr>
          </thead>
          {userListData?.result?.length > 0 ? (
            <tbody className="divide-y divide-gray-200">
              {userListData?.result?.map((user, index) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {index +
                      (userListData?.pagination?.currentPage - 1) *
                        userListData?.pagination?.itemsPerPage +
                      1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.firstname || user.lastname
                      ? `${user.firstname || ""} ${user.lastname || ""}`.trim()
                      : "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.mobile_number || "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.email || "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {user.role
                      ? user.role.toUpperCase().replace("_", " ")
                      : "--"}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        user.is_active
                          ? "text-green-600 bg-green-100"
                          : "text-red-600 bg-red-100"
                      }`}
                    >
                      {user.is_active ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {DateFormatter.formatFullDate(user.created_at)}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center relative">
                      <button
                        onClick={() => toggleMenu(user.id)}
                        className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                      >
                        <MoreVertical className="h-5 w-5" />
                      </button>

                      {openMenuId === user.id && (
                        <div
                          className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                          style={{
                            transform: "translateY(calc(-50% + 2.5rem))",
                            top: "50%",
                          }}
                          ref={menuRef}
                        >
                          <div className="py-1">
                            <button
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                              onClick={() => viewUserDetails(user)}
                            >
                              <Eye className="h-4 w-4 mr-2" />
                              View
                            </button>
                            <button
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                              onClick={() => editUserDetails(user)}
                            >
                              <Pencil className="h-4 w-4 mr-2" />
                              Edit
                            </button>
                            <button
                              className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                              onClick={() => handleDeleteClick(user)}
                            >
                              <Trash2 className="h-4 w-4 mr-2" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan="7"
                  className="px-6 py-8 text-sm text-gray-500 text-center"
                >
                  No user found
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {/* <!---- Pagination component passed as children prop ---> */}
        {userListData?.result?.length > 0 && children}
      </div>
      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this user"}
        isOpen={deletingUser}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </>
  );
}

export default UserTable;
