import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import loader from "../components/loader";
import { addTicketType } from "../network_calls/event_api";
import { getAllOrganizations } from "../network_calls/organization_api";
import { ChevronDown } from "lucide-react";
import { sortAlphabetically } from "../utils/helper";
import { ADMIN } from "../utils/constants";
import useAuthData from "../hooks/use_auth_data";

const TICKET_CATEGORIES = [
  { value: "vip", name: "VIP" },
  { value: "general", name: "General" },
  { value: "other", name: "Other" },
];

function TicketTypeDialog({ onClose, onAddSuccess }) {
  const [organizations, setOrganizations] = useState([]);
  const [loginResult] = useAuthData();

  useEffect(() => {
    if (loginResult?.role === ADMIN) {
      fetchOrganizations();
    }
  }, [loginResult]);

  const fetchOrganizations = async () => {
    try {
      const response = await getAllOrganizations({
        is_active: true,
      });
      if (response.code === 200) {
        const alphabeticallySortedOrganizations = sortAlphabetically(
          response.result
        );
        setOrganizations(alphabeticallySortedOrganizations);
      }
    } catch (error) {
      if (error.code !== 404) {
        toast.error("Failed to fetch organizations");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      ticket_category: "",
      organization_id:
        loginResult?.role === ADMIN ? "" : loginResult?.organization_id,
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Ticket type name is required";
      }

      if (!values.ticket_category) {
        errors.ticket_category = "Ticket category is required";
      }

      if (loginResult?.role === ADMIN && !values.organization_id) {
        errors.organization_id = "Organization is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await addTicketType(values);
        if (response.code === 200) {
          toast.success("Ticket type added successfully");
          onAddSuccess(); // to fetch dropdown list again in parent
          onClose(); // to close modal
        } else {
          toast.error("Failed to add ticket type");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  return (
    <>
      {/*<!-- Start block -->*/}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">
          Add Ticket Type
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Ticket Type Name */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter ticket type name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </div>
              )}
            </div>

            {/* Ticket Category */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Category <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <select
                  name="ticket_category"
                  value={formik.values.ticket_category}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Category</option>
                  {sortAlphabetically(TICKET_CATEGORIES).map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
              </div>
              {formik.touched.ticket_category &&
                formik.errors.ticket_category && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.ticket_category}
                  </div>
                )}
            </div>

            {/* Organization */}
            {loginResult?.role === ADMIN && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Organization <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="organization_id"
                    value={formik.values.organization_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
                {formik.touched.organization_id &&
                  formik.errors.organization_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.organization_id}
                    </div>
                  )}
              </div>
            )}
          </div>

          {/* Description */}
          <div className="space-y-2 mb-6">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              placeholder="Enter ticket type description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
      {/*<!-- End block -->*/}
    </>
  );
}

export default TicketTypeDialog;
