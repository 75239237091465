import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { addOrganization } from "../../network_calls/organization_api";
import { ChevronDown } from "lucide-react";
import { sortAlphabetically } from "../../utils/helper";

const genderDropDownOptions = ["Male", "Female", "Other"];

const AddOrganization = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState({ totalSteps: 2, stepNumber: 1 });

  const formik = useFormik({
    initialValues: {
      org_name: "",
      org_mobile_number: "",
      org_email: "",
      org_address: "",
      firstname: "",
      lastname: "",
      mobile_number: "",
      email: "",
      address: "",
      dob: "",
      gender: "",
      about: "",
      organization_id: "",
      role: "organizer_admin",
    },
    validate: (values) => {
      const errors = {};

      // Step 1 Validation

      if (step.stepNumber === 1) {
        if (!values.org_name) {
          errors.org_name = "Organization name is required";
        }

        if (!values.org_mobile_number) {
          errors.org_mobile_number = "Mobile number is required";
        } else if (!/^\d{10}$/.test(values.org_mobile_number)) {
          errors.org_mobile_number =
            "Please enter a valid 10-digit mobile number";
        }

        if (!values.org_email) {
          errors.org_email = "Email is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.org_email)
        ) {
          errors.org_email = "Invalid email address";
        }
      }

      // Step 2 Validation
      if (step.stepNumber === 2) {
        if (!values.firstname) {
          errors.firstname = "First name is required";
        }

        if (!values.lastname) {
          errors.lastname = "Last name is required";
        }

        if (!values.mobile_number) {
          errors.mobile_number = "Mobile number is required";
        } else if (!/^\d{10}$/.test(values.mobile_number)) {
          errors.mobile_number = "Please enter a valid 10-digit mobile number";
        }

        if (!values.role) {
          errors.role = "Role is required";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        // Submit Step 1 data
        if (step.stepNumber === 1) {
          setStep((curr) => {
            return { ...curr, stepNumber: curr.stepNumber + 1 };
          });
        }

        // Submit Step 2 data
        if (step.stepNumber === 2) {
          const formData = {
            org_name: values.org_name,
            org_mobile_number: values.org_mobile_number,
            org_email: values.org_email,
            org_address: values.org_address,
            firstname: values.firstname,
            lastname: values.lastname,
            mobile_number: values.mobile_number,
            dob: values.dob || null,
            gender: values.gender || null,
            email: values.email,
            about: values.about,
            address: values.address || null,
          };

          const response = await addOrganization(formData);
          if (response.code === 200) {
            toast.success(response.message);
            setTimeout(redirectToOrganizations, 500);
          } else {
            toast.error("Failed to add organization");
          }
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToOrganizations = () => {
    navigate(ROUTES.ORGANIZATIONS.LIST);
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Organizations
          </h1>
          <p className="text-sm text-gray-500">
            <span>Organization Control - </span>
            <span
              onClick={redirectToOrganizations}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Organizations
            </span>
            <span> / Add Organization</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* <!-- Stepper Container --> */}
          <div className="relative">
            {/* <!-- Stepper Progress Line --> */}
            <div className="absolute top-4 left-1/4 w-1/2 h-[2px] bg-gray-300">
              <div
                className="h-[2px] bg-blue-600 transition-all duration-300"
                style={{
                  width: `${
                    ((step.stepNumber - 1) / (step.totalSteps - 1)) * 100
                  }%`,
                }}
              ></div>
            </div>

            {/* <!-- Stepper --> */}
            <ul className="flex justify-around *:px-3 *:py-1 *:rounded-[50%] mb-6 *:text-white *:font-semibold *:z-10">
              <li
                className={`${
                  step.stepNumber >= 1 ? "bg-blue-600" : "bg-stone-400"
                }`}
              >
                1
              </li>
              <li
                className={`${
                  step.stepNumber >= 2 ? "bg-blue-600" : "bg-stone-400"
                }`}
              >
                2
              </li>
            </ul>
          </div>

          {/* <--- Step 1 Form --> */}
          {step.stepNumber === 1 && (
            <>
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Add Organization
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  {/* Organization Name */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Organization Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="org_name"
                      placeholder="Enter organization name"
                      value={formik.values.org_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.org_name && formik.errors.org_name && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.org_name}
                      </div>
                    )}
                  </div>

                  {/* Mobile Number */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="org_mobile_number"
                      placeholder="Enter mobile number"
                      value={formik.values.org_mobile_number}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          formik.setFieldValue("org_mobile_number", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.org_mobile_number &&
                      formik.errors.org_mobile_number && (
                        <div className="text-red-500 text-sm mt-1">
                          {formik.errors.org_mobile_number}
                        </div>
                      )}
                  </div>

                  {/* Email */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="org_email"
                      placeholder="Enter email address"
                      value={formik.values.org_email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.org_email && formik.errors.org_email && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.org_email}
                      </div>
                    )}
                  </div>
                </div>

                {/* Address */}
                <div className="space-y-2 mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <textarea
                    name="org_address"
                    placeholder="Enter organization address"
                    value={formik.values.org_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={4}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Submit Button */}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </form>
            </>
          )}

          {/* <--- Step 2 Form --> */}
          {step.stepNumber === 2 && (
            <>
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Add Organization Admin User
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  {/* First Name */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="Enter first name"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.firstname}
                      </div>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Enter last name"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.lastname}
                      </div>
                    )}
                  </div>

                  {/* Mobile Number */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      name="mobile_number"
                      placeholder="Enter mobile number"
                      value={formik.values.mobile_number}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          formik.setFieldValue("mobile_number", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {formik.touched.mobile_number &&
                      formik.errors.mobile_number && (
                        <div className="text-red-500 text-sm mt-1">
                          {formik.errors.mobile_number}
                        </div>
                      )}
                  </div>

                  {/* Email */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  {/* Date of Birth */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  {/* Gender */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Gender
                    </label>
                    <div className="relative">
                      <select
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select Gender</option>
                        {sortAlphabetically(genderDropDownOptions).map(
                          (opt, index) => (
                            <option key={index} value={opt.toLowerCase()}>
                              {opt}
                            </option>
                          )
                        )}
                      </select>
                      <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                    </div>
                  </div>
                </div>

                {/* About */}
                <div className="space-y-2 mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    About
                  </label>
                  <textarea
                    name="about"
                    placeholder="Tell us about yourself..."
                    value={formik.values.about}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={4}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Address */}
                <div className="space-y-2 mb-6">
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <textarea
                    name="address"
                    placeholder="Enter your address..."
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={4}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Submit Button */}
                <div className="flex justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      setStep((curr) => {
                        return { ...curr, stepNumber: curr.stepNumber - 1 };
                      });
                    }}
                    className="mt-10 mr-4 w-80 flex justify-center items-center text-blue-600 hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2 text-center"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    {formik.isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}
    </div>
  );
};

export default AddOrganization;
