import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  MoreVertical,
  Pencil,
  Trash2,
  Eye,
  Filter,
  Plus,
  X,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import TablePagination from "../../components/table_pagination";
import { useFormik } from "formik";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getAllArtists, getAllGenres } from "../../network_calls/event_api";
import FilterActionsFooter from "../../components/filter_actions_footer";
import GenreDisplay from "../../components/genre_display";
import DateFormatter from "../../utils/date_formatter";
import { sortAlphabetically } from "../../utils/helper";
import MultiselectDropdown from "../../components/multi_select_dropdown";

const ArtistList = () => {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingArtist, setDeletingArtist] = useState(null);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [artistListResult, setArtistListResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [genres, setGenres] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const [filters, setFilters] = useState({
    is_active: "",
    genre_id: [], // Used Array to store multiple genre id's
    popularity: "",
  });

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (clientId) => {
    setOpenMenuId(openMenuId === clientId ? null : clientId);
  };

  const confirmDelete = async () => {};

  const handleDeleteClick = (artist) => {
    setDeletingArtist(artist);
  };

  const handleCloseModal = () => {
    setDeletingArtist(null);
  };

  const handleFilter = async () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const handleSort = async (key) => {
    let newOrder = "asc";

    if (sortConfig.key === key) {
      if (sortConfig.order === "asc") {
        newOrder = "desc";
      } else if (sortConfig.order === "desc") {
        // Reset sorting
        key = "";
        newOrder = "";
      }
    }

    setSortConfig({
      key: key,
      order: newOrder,
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="inline ml-2 h-4 w-4" />;
    }
    return sortConfig.order === "asc" ? (
      <ArrowUp className="inline ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="inline ml-2 h-4 w-4" />
    );
  };

  const formik = useFormik({
    initialValues: {
      is_active: "",
      genre_id: [], // Used array to store multiple genre id's
      popularity: "",
    },
    validate: (values) => {
      const errors = {};
      return errors;
    },
    onSubmit: (values) => {
      handleFilterChange({
        is_active: values.is_active,
        genre_id: values.genre_id.join(","), // Used `join` method to convert to a comma separated string
        popularity: values.popularity,
      });
      handleFilter();
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getArtistList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, itemsPerPage, searchTerm, filters, sortConfig]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleFilterChange = (filterData) => {
    setFilters(filterData);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleResetFilters = () => {
    formik.resetForm();
    handleFilterChange({
      is_active: "",
      genre_id: [], // Reset to initail `genre_id`
    });
    handleFilter();
  };

  const addArtist = () => {
    navigate(ROUTES.ARTISTS.CREATE);
  };

  const viewArtistDetails = (artist) => {
    navigate(ROUTES.ARTISTS.VIEW.replace(":id", artist.id));
  };

  const editArtistDetails = (artist) => {
    navigate(ROUTES.ARTISTS.EDIT.replace(":id", artist.id));
  };

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await getAllGenres();
        const alphabeticallySortedResult = sortAlphabetically(response.result);
        setGenres(alphabeticallySortedResult || []);
      } catch (error) {
        toast.error("Failed to fetch genres");
      }
    };
    fetchGenres();
  }, []);

  const getArtistList = async () => {
    try {
      loader(true);
      const response = await getAllArtists({
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        is_active: filters.is_active,
        genre_id: filters.genre_id,
        popularity: filters.popularity,
        sort_key: sortConfig.key,
        sort_order: sortConfig.order,
      });
      setTotalCount(response.pagination.total);
      setArtistListResult(response.result);
      toast.success("List fetched successfully");
    } catch (error) {
      if (error.code === 404) {
        setTotalCount(0);
        setArtistListResult([]);
      } else {
        // Show error toast for other errors
        toast.error(error.message || "Operation failed");
      }
    } finally {
      loader(false);
    }
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Artists</h1>
          <p className="text-sm text-gray-500">Masters - Artists</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleFilter}
                className="px-4 py-2 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 flex items-center gap-2 text-sm"
              >
                <Filter className="w-5 h-5" />
                Filter
              </button>
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => addArtist()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
              >
                <Plus className="w-5 h-5" />
                Add Artist
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    S.NO.
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center">
                      NAME
                      {getSortIcon("name")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    BIO
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    COUNTRY
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    POPULARITY SCORE
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    GENRES
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    STATUS
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("created_at")}
                  >
                    <div className="flex items-center">
                      CREATED AT
                      {getSortIcon("created_at")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              {artistListResult.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {artistListResult.map((artist, index) => (
                    <tr key={artist.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {index + (currentPage - 1) * itemsPerPage + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {artist.name || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        <div className="line-clamp-4">{artist.bio || "--"}</div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {artist.country || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {artist.popularity_score || "--"}
                      </td>
                      <td className="px-6 py-4 whitespace-normal">
                        <GenreDisplay genres={artist.genre_names} />
                      </td>
                      <td className="px-6 py-4">
                        <span
                          className={`px-2 py-1 text-xs font-medium rounded-full ${
                            artist.is_active
                              ? "text-green-600 bg-green-100"
                              : "text-red-600 bg-red-100"
                          }`}
                        >
                          {artist.is_active ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {DateFormatter.formatFullDate(artist.created_at)}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex justify-center relative">
                          <button
                            onClick={() => toggleMenu(artist.id)}
                            className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                          >
                            <MoreVertical className="h-5 w-5" />
                          </button>

                          {openMenuId === artist.id && (
                            <div
                              className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                              style={{
                                transform: "translateY(calc(-50% + 2.5rem))",
                                top: "50%",
                              }}
                              ref={menuRef}
                            >
                              <div className="py-1">
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() => viewArtistDetails(artist)}
                                >
                                  <Eye className="h-4 w-4 mr-2" />
                                  View
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() => editArtistDetails(artist)}
                                >
                                  <Pencil className="h-4 w-4 mr-2" />
                                  Edit
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                                  onClick={() => handleDeleteClick(artist)}
                                >
                                  <Trash2 className="h-4 w-4 mr-2" />
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No artist found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {/* Pagination */}
          {artistListResult.length > 0 && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={itemsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(newPageSize) => {
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this artist"}
        isOpen={deletingArtist}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />

      {/* <!-- Drawer Filter--> */}
      {isFilterDrawerOpen ? (
        <>
          <div
            className="fixed top-0 left-0 z-50 w-full h-screen bg-black opacity-50"
            onClick={handleFilter}
          />
          <div
            className={`fixed top-0 right-0 z-50 w-full h-screen max-w-sm bg-white flex flex-col transition-transform ${
              isFilterDrawerOpen ? "translate-x-0" : "translate-x-full"
            }`}
            tabIndex="-1"
            aria-labelledby="drawer-label"
          >
            {/* Fixed Header */}
            <div className="px-4 py-3 border-b flex items-center justify-between">
              <h5 className="text-sm font-semibold text-gray-500 uppercase">
                Filter List
              </h5>
              <button
                onClick={handleFilter}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              >
                <X className="w-5 h-5" />
                <span className="sr-only">Close menu</span>
              </button>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col h-full"
            >
              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto p-4 mb-28">
                <div className="space-y-4">
                  {/* Status */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Status
                    </label>
                    <select
                      name="is_active"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.is_active}
                    >
                      <option value="">All</option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>

                  {/* Genre */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Genre
                    </label>

                    <MultiselectDropdown
                      formik={formik}
                      options={genres}
                      placeholder="All Genre"
                      formikValue={"genre_id"}
                    />
                  </div>

                  {/* Popularity Filter */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Popularity is greater than
                    </label>
                    <div className="flex items-center space-x-4">
                      <input
                        type="range"
                        name="popularity"
                        min="0"
                        max="100"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        onChange={formik.handleChange}
                        value={formik.values.popularity}
                      />
                      <span className="text-sm text-gray-600">
                        {formik.values.popularity}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fixed Footer */}
              <div className="border-t p-4 bg-white">
                <FilterActionsFooter onReset={handleResetFilters} />
              </div>
            </form>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ArtistList;
