import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  User,
  Mail,
  Phone,
  CheckCircle,
  XCircle,
  Pencil,
  UserCog,
  CalendarClock,
  Users,
} from "lucide-react";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getMyProfileDetails } from "../../network_calls/profile_api";
import ROUTES from "../../config/routes";
import DateFormatter from "../../utils/date_formatter";

const ViewMyProfileDetails = () => {
  const navigate = useNavigate();
  const [myProfileResult, setMyProfileResult] = useState({});

  useEffect(() => {
    fetchMyUserDetails();
  }, []);

  const fetchMyUserDetails = async () => {
    try {
      loader(true);
      const response = await getMyProfileDetails();
      setMyProfileResult(response.result);
      toast.success("Profile Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  const editMyProfileDetails = (myProfile) => {
    navigate(ROUTES.PROFILE.EDIT.replace(":id", myProfile.id));
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">My Profile</h1>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex flex-row mb-4">
            <img
              className="w-24 h-24 rounded-full bg-gray-300"
              src={myProfileResult.profile_pic}
              alt="user"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Fullname
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <User className="w-4 h-4 text-gray-500" />
                <span>
                  {myProfileResult.firstname} {myProfileResult.lastname}
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Role
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <UserCog className="w-4 h-4 text-gray-500" />
                <span>
                  {myProfileResult.role
                    ? myProfileResult.role.toUpperCase().replace("_", " ")
                    : "--"}
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Email
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Mail className="w-4 h-4 text-gray-500" />
                <span>{myProfileResult.email}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Mobile Number
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Phone className="w-4 h-4 text-gray-500" />
                <span>{myProfileResult.mobile_number}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Gender
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <Users className="w-4 h-4 text-gray-500" />
                <span>
                  {myProfileResult.gender === "male"
                    ? "Male"
                    : myProfileResult.gender === "female"
                    ? "Female"
                    : "Other"}
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Date of Birth
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <CalendarClock className="w-4 h-4 text-gray-500" />
                <span>
                  {DateFormatter.formatFullDate(myProfileResult.dob) || "--"}
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Mobile Number Verified
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                {myProfileResult.is_mobile_verified ? (
                  <CheckCircle className="w-4 h-4 text-green-600" />
                ) : (
                  <XCircle className="w-4 h-4 text-red-600" />
                )}
                <span>
                  {myProfileResult.is_mobile_verified
                    ? "Verified"
                    : "Not Verified"}
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-semibold leading-none text-gray-700">
                Email Verified
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                {myProfileResult.is_email_verified ? (
                  <CheckCircle className="w-4 h-4 text-green-600" />
                ) : (
                  <XCircle className="w-4 h-4 text-red-600" />
                )}
                <span>
                  {myProfileResult.is_email_verified
                    ? "Verified"
                    : "Not Verified"}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Organization
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{myProfileResult.organization_name || "--"}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-semibold leading-none text-gray-700">
                Status
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span
                  className={`px-2 py-1 text-xs font-medium rounded-full ${
                    myProfileResult.is_active
                      ? "text-green-600 bg-green-100"
                      : "text-red-600 bg-red-100"
                  }`}
                >
                  {myProfileResult.is_active ? "Active" : "Inactive"}
                </span>
              </p>
            </div>

            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                Address
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{myProfileResult.address || "--"}</span>
              </p>
            </div>
            <div className="w-full flex flex-col">
              <h2 className="mb-2 font-medium leading-none text-gray-700">
                About
              </h2>
              <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                <span>{myProfileResult.about || "--"}</span>
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => editMyProfileDetails(myProfileResult)}
              type="button"
              className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
              Edit
            </button>
          </div>
        </div>
      </div>
      {/* <!-- End block --> */}
    </div>
  );
};

export default ViewMyProfileDetails;
