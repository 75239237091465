import { useEffect, useState } from "react";
import { KEY_LOGIN_RESULT } from "../utils/constants";
import ROUTES from "../config/routes";
import { useNavigate } from "react-router-dom";

function useAuthData() {
  const [loginResult, setLoginResult] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const storedLoginResult = JSON.parse(
      localStorage.getItem(KEY_LOGIN_RESULT)
    );
    if (storedLoginResult === null) {
      navigate(ROUTES.LOGIN);
    }
    if (storedLoginResult) {
      setLoginResult(storedLoginResult);
    }
  }, [navigate]);
  return [loginResult];
}

export default useAuthData;
