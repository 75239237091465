import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import loader from "../components/loader";
import { addEventCategory } from "../network_calls/event_api";

function EventCategoryDialog({ onClose, onAddSuccess }) {
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (values.name && values.name.length < 2) {
        errors.name = "Name must be at least 2 characters long";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await addEventCategory(values);
        if (response.code === 200) {
          toast.success("Event Category added successfully");
          onAddSuccess(); // to fetch dropdown list again in parent
          onClose(); // to close modal
        } else {
          toast.error("Failed to add event category");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">
          Add Event Category
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Name */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter event category name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </div>
              )}
            </div>
          </div>

          {/* Description */}
          <div className="space-y-2 mb-6">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="Enter event category description..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bio}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {formik.isSubmitting ? "Adding Category..." : "Add Category"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default EventCategoryDialog;
