import Login from "../pages/auth/login";
import MainLayout from "../components/main_layout";
import ROUTES from "./routes";
import AdminDashboard from "../pages/dashboard/admin_dashboard";
import UserList from "../pages/user/user_list";
import AddUser from "../pages/user/add_user";
import OrganizationList from "../pages/organization/organization_list";
import AddOrganization from "../pages/organization/add_organization";
import EventList from "../pages/event/event_list";
import AddEvent from "../pages/event/add_event";
import EventCategories from "../pages/event/event_categories";
import AddEventCategory from "../pages/event/add_event_category";
import EventVenues from "../pages/event/event_venues";
import AddEventVenue from "../pages/event/add_event_venue";
import ArtistList from "../pages/artist/artist_list";
import AddArtist from "../pages/artist/add_artist";
import GenreList from "../pages/genre/genre_list";
import AddGenre from "../pages/genre/add_genre";
import TicketTypeList from "../pages/ticket_type/ticket_type_list";
import AddTicketType from "../pages/ticket_type/add_ticket_type";
import OrganizationContractList from "../pages/organization/organization_contract_list";
import AddOrganizationContract from "../pages/organization/add_organization_contract";
import ViewUserDetails from "../pages/user/view_user_details";
import ViewArtistDetails from "../pages/artist/view_artist_details";
import ViewGenreDetails from "../pages/genre/view_genre_details";
import ViewEventCategoryDetails from "../pages/event/view_event_category_details";
import ViewEventVenueDetails from "../pages/event/view_event_venue_details";
import ViewOrganizationDetails from "../pages/organization/view_organization_details";
import ViewTicketTypeDetails from "../pages/ticket_type/view_ticket_type_details";
import EditUser from "../pages/user/edit_user";
import EditArtist from "../pages/artist/edit_artist";
import EditGenre from "../pages/genre/edit_genre";
import EditEventCategory from "../pages/event/edit_event_category";
import EditEventVenue from "../pages/event/edit_event_venue";
import EditOrganization from "../pages/organization/edit_organization";
import EditTicketType from "../pages/ticket_type/edit_ticket_type";
import ViewEventDetails from "../pages/event/view_event_details";
import ViewMyProfileDetails from "../pages/my_profile/view_my_profile";
import EditMyProfileDetails from "../pages/my_profile/edit_my_profile";
import EditEvent from "../pages/event/edit_event";

export const routeConfig = [
  {
    path: ROUTES.LOGIN,
    element: Login,
  },
  {
    path: ROUTES.DASHBOARD,
    element: MainLayout,
    children: [
      {
        index: true,
        element: AdminDashboard,
      },
      {
        path: ROUTES.EVENTS.LIST,
        element: EventList,
      },
      {
        path: ROUTES.EVENTS.CREATE,
        element: AddEvent,
      },
      {
        path: ROUTES.EVENTS.VIEW,
        element: ViewEventDetails,
      },
      {
        path: ROUTES.EVENTS.EDIT,
        element: EditEvent,
      },
      {
        path: ROUTES.EVENTS.CATEGORIES.LIST,
        element: EventCategories,
      },
      {
        path: ROUTES.EVENTS.CATEGORIES.CREATE,
        element: AddEventCategory,
      },
      {
        path: ROUTES.EVENTS.CATEGORIES.VIEW,
        element: ViewEventCategoryDetails,
      },
      {
        path: ROUTES.EVENTS.CATEGORIES.EDIT,
        element: EditEventCategory,
      },
      {
        path: ROUTES.EVENTS.VENUES.LIST,
        element: EventVenues,
      },
      {
        path: ROUTES.EVENTS.VENUES.CREATE,
        element: AddEventVenue,
      },
      {
        path: ROUTES.EVENTS.VENUES.VIEW,
        element: ViewEventVenueDetails,
      },
      {
        path: ROUTES.EVENTS.VENUES.EDIT,
        element: EditEventVenue,
      },
      {
        path: ROUTES.USERS.LIST,
        element: UserList,
      },
      {
        path: ROUTES.USERS.VIEW,
        element: ViewUserDetails,
      },
      {
        path: ROUTES.USERS.CREATE,
        element: AddUser,
      },
      {
        path: ROUTES.USERS.EDIT,
        element: EditUser,
      },
      {
        path: ROUTES.ORGANIZATIONS.LIST,
        element: OrganizationList,
      },
      {
        path: ROUTES.ORGANIZATIONS.CREATE,
        element: AddOrganization,
      },
      {
        path: ROUTES.ORGANIZATIONS.VIEW,
        element: ViewOrganizationDetails,
      },
      {
        path: ROUTES.ORGANIZATIONS.EDIT,
        element: EditOrganization,
      },
      {
        path: ROUTES.ARTISTS.LIST,
        element: ArtistList,
      },
      {
        path: ROUTES.ARTISTS.CREATE,
        element: AddArtist,
      },
      {
        path: ROUTES.ARTISTS.VIEW,
        element: ViewArtistDetails,
      },
      {
        path: ROUTES.ARTISTS.EDIT,
        element: EditArtist,
      },
      {
        path: ROUTES.GENRES.LIST,
        element: GenreList,
      },
      {
        path: ROUTES.GENRES.CREATE,
        element: AddGenre,
      },
      {
        path: ROUTES.GENRES.VIEW,
        element: ViewGenreDetails,
      },
      {
        path: ROUTES.GENRES.EDIT,
        element: EditGenre,
      },
      {
        path: ROUTES.TICKET_TYPES.LIST,
        element: TicketTypeList,
      },
      {
        path: ROUTES.TICKET_TYPES.CREATE,
        element: AddTicketType,
      },
      {
        path: ROUTES.TICKET_TYPES.VIEW,
        element: ViewTicketTypeDetails,
      },
      {
        path: ROUTES.TICKET_TYPES.EDIT,
        element: EditTicketType,
      },
      {
        path: ROUTES.ORGANIZATIONS.CONTRACTS.LIST,
        element: OrganizationContractList,
      },
      {
        path: ROUTES.ORGANIZATIONS.CONTRACTS.CREATE,
        element: AddOrganizationContract,
      },
      {
        path: ROUTES.PROFILE.VIEW,
        element: ViewMyProfileDetails,
      },
      {
        path: ROUTES.PROFILE.EDIT,
        element: EditMyProfileDetails,
      },
    ],
  },
];
