import axiosClient from "../utils/api_client";

export function login(data) {
  return axiosClient.post("/auth/user/login", data);
}

export function verifyOtp(data) {
  return axiosClient.post("/auth/user/verify-otp", data);
}

export function logout(data) {
  return axiosClient.post("/auth/user/logout", data);
}
