import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  User,
  Mail,
  Phone,
  CheckCircle,
  XCircle,
  Pencil,
  Trash,
  UserCog,
  CalendarClock,
  Users,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import {
  getUserDetails,
  getUserAttendedEventsDetails,
} from "../../network_calls/user_api";
import ROUTES from "../../config/routes";
import DateFormatter from "../../utils/date_formatter";
import useAuthData from "../../hooks/use_auth_data";
import { ADMIN } from "../../utils/constants";

function AttendedEventsDetails({ attendedEventsListResult }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-gray-50 border-y border-gray-200">
          <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
            <th>S.NO.</th>
            <th>EVENT NAME</th>
            <th>START DATE</th>
            <th>END DATE</th>
            <th>VENUE NAME</th>
            <th>TICKET PURCHASED</th>
            <th>PAYMENT STATUS</th>
            <th>EVENT ATTENDED STATUS</th>
            <th>TOTAL AMOUNT</th>
          </tr>
        </thead>
        {attendedEventsListResult.length > 0 ? (
          <tbody className="divide-y divide-gray-200">
            {attendedEventsListResult.map((event, index) => (
              <tr
                key={index}
                className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
              >
                <td>{index + 1}</td>
                <td>{event.event_name}</td>
                <td>{DateFormatter.formatFullDate(event.event_start_date)}</td>
                <td>{DateFormatter.formatFullDate(event.event_end_date)}</td>
                <td>{event.venue_name}</td>
                <td>{event.quantity}</td>
                <td>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                      event.payment_status === "paid"
                        ? "text-green-600 bg-green-100"
                        : "text-red-600 bg-red-100"
                    }`}
                  >
                    {event.payment_status === "paid" ? "Paid" : "Pending"}
                  </span>
                </td>
                <td>
                  <span
                    className={`px-2 py-1 text-xs font-medium rounded-full ${
                      event.tickets_scanned > 0
                        ? "text-green-600 bg-green-100"
                        : "text-red-600 bg-red-100"
                    }`}
                  >
                    {event.tickets_scanned > 0 ? "Attended" : "Not Attended"}
                  </span>
                </td>
                <td>₹ {event.total_amount}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                colSpan="7"
                className="px-6 py-8 text-sm text-gray-500 text-center"
              >
                No events attedend
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

const ViewUserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userResult, setUserResult] = useState({});
  const [deletingUser, setDeletingUser] = useState(null);
  const [activeTab, setActiveTab] = useState("user_details");
  const [attendedEventsListResult, setAttendedEventsListResult] = useState([]);
  const [loginResult] = useAuthData();

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleDeleteClick = (user) => {
    setDeletingUser(user);
  };

  const handleCloseModal = () => {
    setDeletingUser(null);
  };

  const confirmDelete = async () => {};

  const redirectToUsers = () => {
    navigate(ROUTES.USERS.LIST);
  };

  const editUserDetails = (user) => {
    navigate(ROUTES.USERS.EDIT.replace(":id", user.id));
  };

  const fetchUserDetails = async () => {
    try {
      loader(true);
      const response = await getUserDetails(id);
      setUserResult(response.result);
      toast.success("User Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  // Fetches attedend events based on `user id` data and and update state, avoinding re-fetch if data already exists
  const fetchAttendedEventsDetails = async () => {
    try {
      if (!attendedEventsListResult.length) {
        loader(true);
        const response = await getUserAttendedEventsDetails(id);
        setAttendedEventsListResult(response.result);
        toast.success("Attended Events Detail fetched successfully");
      }
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  // Triggers active-tab change
  function handleActiveTabChange(tabName) {
    if (tabName === "attended_events") fetchAttendedEventsDetails();
    setActiveTab(tabName);
  }

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Users</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToUsers}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Users
            </span>
            <span> / {id}</span>
          </p>
        </div>

        {/* <!-- Tab Container -->  */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* <!-- Tab Change Button --> */}
          <div className="flex gap-7">
            <button
              onClick={() => handleActiveTabChange("user_details")}
              className={`text-xl font-semibold text-gray-800 mb-8 ${
                activeTab === "user_details"
                  ? "underline underline-offset-8 decoration-primary-600"
                  : ""
              }`}
            >
              User details
            </button>
            {loginResult?.role === ADMIN && (
              <button
                onClick={() => handleActiveTabChange("attended_events")}
                className={`text-xl font-semibold text-gray-800 mb-8 ${
                  activeTab === "attended_events"
                    ? "underline  underline-offset-8 decoration-primary-600"
                    : ""
                }`}
              >
                Attended Events
              </button>
            )}
          </div>
          {/*<!-- User Details Tab -->*/}
          {activeTab === "user_details" && (
            <>
              <div className="flex flex-row mb-4">
                <img
                  className="w-24 h-24 rounded-full bg-gray-300"
                  src={userResult.profile_pic}
                  alt="user"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Fullname
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <User className="w-4 h-4 text-gray-500" />
                    <span>
                      {userResult.firstname} {userResult.lastname}
                    </span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Role
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <UserCog className="w-4 h-4 text-gray-500" />
                    <span>
                      {userResult.role
                        ? userResult.role.toUpperCase().replace("_", " ")
                        : "--"}
                    </span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Email
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <Mail className="w-4 h-4 text-gray-500" />
                    <span>{userResult.email}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Mobile Number
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <Phone className="w-4 h-4 text-gray-500" />
                    <span>{userResult.mobile_number}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Gender
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <Users className="w-4 h-4 text-gray-500" />
                    <span>
                      {userResult.gender === "male"
                        ? "Male"
                        : userResult.gender === "female"
                        ? "Female"
                        : "Other"}
                    </span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Date of Birth
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <CalendarClock className="w-4 h-4 text-gray-500" />
                    <span>
                      {DateFormatter.formatFullDate(userResult.dob) || "--"}
                    </span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Mobile Number Verified
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    {userResult.is_mobile_verified ? (
                      <CheckCircle className="w-4 h-4 text-green-600" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-600" />
                    )}
                    <span>
                      {userResult.is_mobile_verified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-semibold leading-none text-gray-700">
                    Email Verified
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    {userResult.is_email_verified ? (
                      <CheckCircle className="w-4 h-4 text-green-600" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-600" />
                    )}
                    <span>
                      {userResult.is_email_verified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </p>
                </div>

                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Organization
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    {userResult.organization_name ? (
                      <span
                        onClick={() =>
                          navigate(
                            ROUTES.ORGANIZATIONS.VIEW.replace(
                              ":id",
                              userResult.organization_id
                            )
                          )
                        }
                        className="cursor-pointer hover:text-blue-600 transition-colors"
                      >
                        {userResult.organization_name}
                      </span>
                    ) : (
                      <span>--</span>
                    )}
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-semibold leading-none text-gray-700">
                    Status
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        userResult.is_active
                          ? "text-green-600 bg-green-100"
                          : "text-red-600 bg-red-100"
                      }`}
                    >
                      {userResult.is_active ? "Active" : "Inactive"}
                    </span>
                  </p>
                </div>

                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Address
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>{userResult.address || "--"}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    About
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>{userResult.about || "--"}</span>
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4 mt-8">
                <button
                  onClick={() => editUserDetails(userResult)}
                  type="button"
                  className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(userResult)}
                  type="button"
                  className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Trash className="w-5 h-5 mr-1.5 -ml-1" />
                  Delete
                </button>
              </div>
            </>
          )}
          {/*<!-- Attended Events Tab -->*/}
          {activeTab === "attended_events" && (
            <>
              <AttendedEventsDetails
                attendedEventsListResult={attendedEventsListResult}
              />
            </>
          )}
        </div>
      </div>
      {/* <!-- End block --> */}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this user"}
        isOpen={deletingUser}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewUserDetails;
