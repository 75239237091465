import React, { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import loader from "./loader";
import { getAllOrganizations } from "../network_calls/organization_api";
import { getOrganizationUsers } from "../network_calls/user_api";
import { addEventVenue } from "../network_calls/event_api";
import { sortAlphabetically } from "../utils/helper";
import { ADMIN } from "../utils/constants";
import useAuthData from "../hooks/use_auth_data";

const EventVenueDialog = ({ onClose, onAddSuccess }) => {
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [loginResult] = useAuthData();

  useEffect(() => {
    if (loginResult?.role === ADMIN) {
      fetchOrganizations();
    } else {
      formik.setFieldValue("organization_id", loginResult?.organization_id);
      formik.setFieldValue("user_id", loginResult?.id);
    }
  }, [loginResult]);

  useEffect(() => {
    fetch("/assets/json/state_city.json")
      .then((response) => response.json())
      .then((data) => {
        setStateData(data.states);
      })
      .catch((error) => {
        console.error("Error loading state data:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      const stateInfo = stateData.find((state) => state.name === selectedState);
      setCities(stateInfo ? stateInfo.cities : []);
      setSelectedCity("");
    }
  }, [selectedState, stateData]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    formik.setFieldValue("state", e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    formik.setFieldValue("city", e.target.value);
  };

  const fetchOrganizations = async () => {
    try {
      const response = await getAllOrganizations({
        is_active: true,
      });
      if (response.code === 200) {
        const alphabeticallySortedOrganizations = sortAlphabetically(
          response.result
        );
        setOrganizations(alphabeticallySortedOrganizations);
      }
    } catch (error) {
      if (error.code !== 404) {
        toast.error("Failed to fetch organizations");
      }
    }
  };

  const fetchUsers = async (organizationId) => {
    try {
      const response = await getOrganizationUsers(organizationId);
      if (response.code === 200) {
        const alphabeticallySortedUsers = sortAlphabetically(
          response.result,
          "firstname"
        );
        setUsers(alphabeticallySortedUsers);
      }
    } catch (error) {
      toast.error("Failed to fetch users");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      capacity: null,
      address: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
      organization_id: "",
      user_id: "",
      is_parking_available: "",
      is_food_available: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Venue name is required";
      }

      if (!values.address) {
        errors.address = "Address is required";
      }

      if (!values.city) {
        errors.city = "City is required";
      }

      if (!values.state) {
        errors.state = "State is required";
      }

      if (!values.country) {
        errors.country = "Country is required";
      }

      if (!values.pincode) {
        errors.pincode = "Pincode is required";
      }

      if (loginResult?.role === ADMIN && !values.organization_id) {
        errors.organization_id = "Organization is required";
      }

      if (loginResult?.role === ADMIN && !values.user_id) {
        errors.user_id = "User is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const formData = {
          ...values,
          is_parking_available: values.is_parking_available || null,
          is_food_available: values.is_food_available || null,
        };
        const response = await addEventVenue(formData);
        if (response.code === 200) {
          toast.success("Venue added successfully");
          onAddSuccess();
          onClose();
        } else {
          toast.error("Failed to add venue");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const handleOrganizationChange = (e) => {
    const organizationId = e.target.value;
    formik.setFieldValue("organization_id", organizationId);
    formik.setFieldValue("user_id", "");
    if (organizationId) {
      fetchUsers(organizationId);
    } else {
      setUsers([]);
    }
  };

  return (
    <div className="rounded-md shadow-sm p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">
        Add Event Venue
      </h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Name */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Venue Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter venue name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.name}
              </div>
            )}
          </div>

          {/* Capacity */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Capacity
            </label>
            <input
              type="number"
              name="capacity"
              placeholder="Enter venue capacity"
              value={formik.values.capacity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {formik.touched.capacity && formik.errors.capacity && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.capacity}
              </div>
            )}
          </div>

          {/* Organization */}
          {loginResult?.role === ADMIN && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Organization <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <select
                  name="organization_id"
                  value={formik.values.organization_id}
                  onChange={handleOrganizationChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
              </div>
              {formik.touched.organization_id &&
                formik.errors.organization_id && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.organization_id}
                  </div>
                )}
            </div>
          )}

          {/* User */}
          {loginResult?.role === ADMIN && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                User <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <select
                  name="user_id"
                  value={formik.values.user_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  disabled={!formik.values.organization_id}
                >
                  <option value="">Select User</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.firstname} {user.lastname}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
              </div>
              {formik.touched.user_id && formik.errors.user_id && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.user_id}
                </div>
              )}
            </div>
          )}

          {/* State Dropdown */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              State <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <select
                name="state"
                value={selectedState}
                onChange={handleStateChange}
                onBlur={formik.handleBlur}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select State</option>
                {stateData.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
            </div>
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.state}
              </div>
            )}
          </div>

          {/* City Dropdown */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              City <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <select
                name="city"
                value={selectedCity}
                onChange={handleCityChange}
                onBlur={formik.handleBlur}
                disabled={!selectedState}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
            </div>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.city}
              </div>
            )}
          </div>

          {/* Country */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="country"
              placeholder="Enter country"
              disabled
              value={formik.values.country}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 cursor-not-allowed"
            />
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.country}
              </div>
            )}
          </div>

          {/* Pincode */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Pincode <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="pincode"
              placeholder="Enter pincode"
              value={formik.values.pincode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {formik.touched.pincode && formik.errors.pincode && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.pincode}
              </div>
            )}
          </div>

          {/* Parking Available */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Parking Available
            </label>
            <div className="relative">
              <select
                id="is_parking_available"
                name="is_parking_available"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.is_parking_available}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
            </div>
          </div>

          {/* Food Available */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Food Available
            </label>
            <div className="relative">
              <select
                id="is_food_available"
                name="is_food_available"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.is_food_available}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
            </div>
          </div>
        </div>

        {/* Address */}
        <div className="space-y-2 mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Address <span className="text-red-500">*</span>
          </label>
          <textarea
            name="address"
            placeholder="Enter venue address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          {formik.touched.address && formik.errors.address && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.address}
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {formik.isSubmitting ? "Adding Venue..." : "Add Venue"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventVenueDialog;
