import React, { useEffect, useState } from "react";
import { ChevronDown, Plus, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  addEvent,
  getAllEventVenues,
  getAllEventCategories,
  getAllTicketType,
  getAllArtists,
} from "../../network_calls/event_api";
import { getAllOrganizations } from "../../network_calls/organization_api";
import MultiselectDropdown from "../../components/multi_select_dropdown";
import { sortAlphabetically } from "../../utils/helper";
import Modal from "../../components/modal";
import EventVenueDialog from "../../components/event_venue_dialog";
import EventCategoryDialog from "../../components/event_category_dialog";
import AtristDialog from "../../components/artist_dialog";
import TicketTypeDialog from "../../components/ticket_type_dialog";
import {
  durationTypeDropdownOptions,
  eventTypeDropdownOptions,
  languageDropDownOptions,
  audienceTypeOptions,
} from "../../utils/constants";

const AddEvent = () => {
  const navigate = useNavigate();
  const [venues, setVenues] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [artists, setArtists] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [modalKey, setModalKey] = useState(0);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      loader(true);

      // Make all API calls but handle each response individually
      const venuesPromise = getAllEventVenues().catch((err) => ({
        code: err.code || 500,
      }));
      const orgsPromise = getAllOrganizations().catch((err) => ({
        code: err.code || 500,
      }));
      const categoriesPromise = getAllEventCategories().catch((err) => ({
        code: err.code || 500,
      }));
      const artistsPromise = getAllArtists().catch((err) => ({
        code: err.code || 500,
      }));
      const ticketTypesPromise = getAllTicketType().catch((err) => ({
        code: err.code || 500,
      }));

      // Wait for all promises to resolve
      const [venuesRes, orgsRes, categoriesRes, artistsRes, ticketTypesRes] =
        await Promise.all([
          venuesPromise,
          orgsPromise,
          categoriesPromise,
          artistsPromise,
          ticketTypesPromise,
        ]);

      // Handle each response individually
      if (venuesRes.code === 200) setVenues(venuesRes.result);
      if (orgsRes.code === 200) setOrganizations(orgsRes.result);
      if (categoriesRes.code === 200) setCategories(categoriesRes.result);
      if (artistsRes.code === 200) setArtists(artistsRes.result);
      if (ticketTypesRes.code === 200) setTicketTypes(ticketTypesRes.result);

      // Check if any non-404 errors occurred
      const errors = [
        venuesRes,
        orgsRes,
        categoriesRes,
        artistsRes,
        ticketTypesRes,
      ].filter((res) => res.code !== 200 && res.code !== 404);

      if (errors.length > 0) {
        toast.error("Failed to fetch some required data");
      }
    } catch (error) {
      // This will only catch errors not related to the API responses
      toast.error("An unexpected error occurred");
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      header_image: null,
      name: "",
      description: "",
      published_date: "",
      ticket_live_start_date: "",
      ticket_live_end_date: "",
      event_start_date: "",
      event_end_date: "",
      event_start_time: "",
      event_end_time: "",
      event_venue_id: "",
      organization_id: "",
      event_category_id: "",
      event_ticket_pricings: [],
      event_artists: [],
      duration_type: "",
      event_type: "",
      language: "",
      audience_type: "all",
    },
    validate: (values) => {
      const errors = {};

      // Add image validation
      if (!values.header_image) {
        errors.header_image = "Header image is required";
      } /*else if (values.header_image) {
        // Validate file type
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!allowedTypes.includes(values.header_image.type)) {
          errors.header_image =
            "Please upload a valid image file (JPEG, PNG, or GIF)";
        }

        // Validate file size (e.g., max 5MB)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        if (values.header_image.size > maxSize) {
          errors.header_image = "Image size should be less than 5MB";
        }
      }*/

      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.published_date) {
        errors.published_date = "Publish date is required";
      }
      if (!values.ticket_live_start_date) {
        errors.ticket_live_start_date = "Ticket start date is required";
      }
      if (!values.ticket_live_end_date) {
        errors.ticket_live_end_date = "Ticket end date is required";
      }
      if (!values.event_start_date) {
        errors.event_start_date = "Event start date is required";
      }
      if (!values.event_end_date) {
        errors.event_end_date = "Event end date is required";
      }
      if (!values.event_start_time) {
        errors.event_start_time = "Event start time is required";
      }
      if (!values.event_end_time) {
        errors.event_end_time = "Event end time is required";
      }
      if (!values.event_venue_id) {
        errors.event_venue_id = "Venue is required";
      }
      if (!values.organization_id) {
        errors.organization_id = "Organization is required";
      }
      if (!values.event_category_id) {
        errors.event_category_id = "Category is required";
      }
      if (!values.duration_type) {
        errors.duration_type = "Duration type is required";
      }
      if (!values.event_type) {
        errors.event_type = "Event type is required";
      }
      if (!values.audience_type) {
        errors.audience_type = "Audience type is required";
      }

      // Date validation
      if (values.ticket_live_end_date < values.ticket_live_start_date) {
        errors.ticket_live_end_date =
          "Ticket end date must be after start date";
      }
      if (values.event_end_date < values.event_start_date) {
        errors.event_end_date = "Event end date must be after start date";
      }

      // Ticket pricing validation
      const ticketErrors = [];
      values.event_ticket_pricings.forEach((pricing, index) => {
        const pricingError = {};
        if (!pricing.ticket_price || pricing.ticket_price <= 0) {
          pricingError.ticket_price = "Valid price is required";
        }
        if (
          !pricing.ticket_total_quantity ||
          pricing.ticket_total_quantity <= 0
        ) {
          pricingError.ticket_total_quantity = "Valid quantity is required";
        }
        if (Object.keys(pricingError).length > 0) {
          ticketErrors[index] = pricingError;
        }
      });

      if (ticketErrors.length > 0) {
        errors.event_ticket_pricings = ticketErrors;
      }

      if (values.event_ticket_pricings.length === 0) {
        errors.event_ticket_pricings = "At least one ticket type is required";
      }

      // Artist validation
      if (values.event_artists.length === 0) {
        errors.event_artists = "At least one artist is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        // Handle form submission
        const formData = new FormData();
        // Append all your form fields
        Object.keys(values).forEach((key) => {
          if (key === "header_image") {
            formData.append("header_image", values.header_image);
          } else if (key === "event_ticket_pricings") {
            formData.append(key, JSON.stringify(values.event_ticket_pricings));
          } else if (key === "event_artists") {
            formData.append(key, JSON.stringify(values.event_artists));
          } else {
            formData.append(key, values[key]);
          }
        });
        const response = await addEvent(formData);
        if (response.code === 200) {
          toast.success("Event added successfully");
          setTimeout(() => {
            redirectToEvents();
          }, 500);
        } else {
          toast.error("Failed to add event");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const handleAddTicketType = () => {
    if (ticketTypes.length === 0) {
      openModal("ticketType");
    } else {
      const firstAvailableType = ticketTypes.find(
        (type) =>
          !formik.values.event_ticket_pricings.some(
            (pricing) => pricing.ticket_type_id === type.id
          )
      );

      if (firstAvailableType) {
        formik.setFieldValue("event_ticket_pricings", [
          ...formik.values.event_ticket_pricings,
          {
            ticket_type_id: firstAvailableType.id,
            ticket_price: "",
            ticket_total_quantity: "",
          },
        ]);
      } else {
        toast.warning("All ticket types have been added");
      }
    }
  };

  const handleRemoveTicketType = (index) => {
    const newPricings = [...formik.values.event_ticket_pricings];
    newPricings.splice(index, 1);
    formik.setFieldValue("event_ticket_pricings", newPricings);
  };

  const redirectToEvents = () => {
    navigate(ROUTES.EVENTS.LIST);
  };

  const getTicketTypeName = (typeId) => {
    const ticketType = ticketTypes.find((type) => type.id === typeId);
    return ticketType ? ticketType.name : "";
  };

  // Function to open a new modal instance with the modalType passed as parameter
  const openModal = (modalType) => {
    setActiveModal(modalType);
    setModalKey(Date.now()); // Forces re-mount when reopening
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Events</h1>
          <p className="text-sm text-gray-500">
            <span>Event Control - </span>
            <span
              onClick={redirectToEvents}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Events
            </span>
            <span> / Add Event</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Add Event
          </h2>

          <form onSubmit={formik.handleSubmit}>
            {/* Choose Header Image */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Choose Image <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                id="header_image"
                name="header_image"
                accept="image/*" // This will only allow image files
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    formik.setFieldValue("header_image", file);
                  }
                }}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              {formik.touched.header_image && formik.errors.header_image && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.header_image}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter event name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>

              {/* Publish Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Publish Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="published_date"
                  name="published_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.published_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.published_date &&
                  formik.errors.published_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.published_date}
                    </div>
                  )}
              </div>

              {/* Ticket Live Start Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Ticket Start Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="ticket_live_start_date"
                  name="ticket_live_start_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_live_start_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.ticket_live_start_date &&
                  formik.errors.ticket_live_start_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.ticket_live_start_date}
                    </div>
                  )}
              </div>

              {/* Ticket Live End Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Ticket End Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="ticket_live_end_date"
                  name="ticket_live_end_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_live_end_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.ticket_live_end_date &&
                  formik.errors.ticket_live_end_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.ticket_live_end_date}
                    </div>
                  )}
              </div>

              {/* Event Start Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event Start Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="event_start_date"
                  name="event_start_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_start_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_start_date &&
                  formik.errors.event_start_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_start_date}
                    </div>
                  )}
              </div>

              {/* Event End Date */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event End Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="event_end_date"
                  name="event_end_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_end_date}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_end_date &&
                  formik.errors.event_end_date && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_end_date}
                    </div>
                  )}
              </div>

              {/* Event Start Time */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event Start Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="time"
                  id="event_start_time"
                  name="event_start_time"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_start_time}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_start_time &&
                  formik.errors.event_start_time && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_start_time}
                    </div>
                  )}
              </div>

              {/* Event End Time */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event End Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="time"
                  id="event_end_time"
                  name="event_end_time"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_end_time}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.event_end_time &&
                  formik.errors.event_end_time && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_end_time}
                    </div>
                  )}
              </div>

              {/* Venue Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Venue <span className="text-red-500">*</span>
                </label>
                <div className="flex gap-2">
                  <select
                    id="event_venue_id"
                    name="event_venue_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.event_venue_id}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Venue</option>
                    {venues.map((venue) => (
                      <option key={venue.id} value={venue.id}>
                        {venue.name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => openModal("venue")}
                    className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    <Plus size={20} />
                  </button>
                </div>
                {formik.touched.event_venue_id &&
                  formik.errors.event_venue_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_venue_id}
                    </div>
                  )}
              </div>

              {/* Organization Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Organization <span className="text-red-500">*</span>
                </label>
                <select
                  id="organization_id"
                  name="organization_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.organization_id}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Organization</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
                {formik.touched.organization_id &&
                  formik.errors.organization_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.organization_id}
                    </div>
                  )}
              </div>

              {/* Category Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Category <span className="text-red-500">*</span>
                </label>
                <div className="flex gap-2">
                  <select
                    id="event_category_id"
                    name="event_category_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.event_category_id}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => openModal("category")}
                    className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    <Plus size={20} />
                  </button>
                </div>
                {formik.touched.event_category_id &&
                  formik.errors.event_category_id && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_category_id}
                    </div>
                  )}
              </div>

              {/* Artist Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Artist <span className="text-red-500">*</span>
                </label>
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <MultiselectDropdown
                      formik={formik}
                      options={artists}
                      formikValue="event_artists"
                      placeholder="Select Artist"
                      className="w-full px-4 py-2 pr-10 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
                  </div>
                  <button
                    type="button"
                    onClick={() => openModal("artist")}
                    className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    <Plus size={20} />
                  </button>
                </div>
                {formik.touched.event_artists &&
                  formik.errors.event_artists && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.event_artists}
                    </div>
                  )}
              </div>

              {/* Duration type Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Duration type <span className="text-red-500">*</span>
                </label>
                <select
                  id="duration_type"
                  name="duration_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.duration_type}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Duration Type</option>
                  {sortAlphabetically(durationTypeDropdownOptions).map(
                    ({ name, value }, index) => (
                      <option key={index} value={value}>
                        {name}
                      </option>
                    )
                  )}
                </select>
                {formik.touched.duration_type &&
                  formik.errors.duration_type && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.duration_type}
                    </div>
                  )}
              </div>

              {/* Event type Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event type <span className="text-red-500">*</span>
                </label>
                <select
                  id="event_type"
                  name="event_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.event_type}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Event Type</option>
                  {sortAlphabetically(eventTypeDropdownOptions).map(
                    ({ name, value }, index) => (
                      <option key={index} value={value}>
                        {name}
                      </option>
                    )
                  )}
                </select>
                {formik.touched.event_type && formik.errors.event_type && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.event_type}
                  </div>
                )}
              </div>

              {/* Language Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Language
                </label>
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <MultiselectDropdown
                      formik={formik}
                      options={languageDropDownOptions}
                      formikValue="language"
                      placeholder="Select Language"
                      className="w-full px-4 py-2 pr-10 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
                  </div>
                </div>
              </div>

              {/* Audience Type Dropdown */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Audience Type <span className="text-red-500">*</span>
                </label>
                <select
                  id="audience_type"
                  name="audience_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.audience_type}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Audience Type</option>
                  {audienceTypeOptions.map(({ name, value }, index) => (
                    <option key={index} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
                {formik.touched.audience_type &&
                  formik.errors.audience_type && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors.audience_type}
                    </div>
                  )}
              </div>
            </div>

            {/* Description */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter event description..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Ticket Pricing Section */}
            <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Ticket Pricing
                </h3>

                <button
                  type="button"
                  onClick={handleAddTicketType}
                  className="px-4 py-2 text-sm bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center gap-2"
                >
                  <Plus size={16} />
                  Add Ticket Type
                </button>
              </div>

              {typeof formik.errors.event_ticket_pricings === "string" && (
                <div className="text-red-500 text-sm mb-4">
                  {formik.errors.event_ticket_pricings}
                </div>
              )}

              {formik.values.event_ticket_pricings.map((pricing, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6 p-6 border rounded-lg bg-gray-50"
                >
                  {/* Ticket Type */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Ticket Type
                    </label>
                    <div className="flex gap-2">
                      <select
                        value={pricing.ticket_type_id}
                        onChange={(e) => {
                          const newPricings = [
                            ...formik.values.event_ticket_pricings,
                          ];
                          newPricings[index].ticket_type_id = Number(
                            e.target.value
                          );
                          formik.setFieldValue(
                            "event_ticket_pricings",
                            newPricings
                          );
                        }}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      >
                        {ticketTypes
                          .filter(
                            (type) =>
                              type.id === pricing.ticket_type_id ||
                              !formik.values.event_ticket_pricings.some(
                                (p) => p.ticket_type_id === type.id
                              )
                          )
                          .map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => openModal("ticketType")}
                        className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                      >
                        <Plus size={20} />
                      </button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Price
                    </label>
                    <input
                      type="text"
                      value={pricing.ticket_price}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only numbers using a regex in the input field
                        if (/^\d*$/.test(value)) {
                          const newPricings = [
                            ...formik.values.event_ticket_pricings,
                          ];
                          newPricings[index].ticket_price = Number(value);
                          formik.setFieldValue(
                            "event_ticket_pricings",
                            newPricings
                          );
                        }
                      }}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      placeholder="Enter price"
                    />
                    {formik.errors.event_ticket_pricings?.[index]
                      ?.ticket_price && (
                      <div className="text-red-500 text-sm mt-1">
                        {
                          formik.errors.event_ticket_pricings[index]
                            .ticket_price
                        }
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Quantity
                    </label>
                    <input
                      type="text"
                      value={pricing.ticket_total_quantity || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          const newPricings = [
                            ...formik.values.event_ticket_pricings,
                          ];
                          newPricings[index].ticket_total_quantity =
                            Number(value);
                          formik.setFieldValue(
                            "event_ticket_pricings",
                            newPricings
                          );
                        }
                      }}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                      placeholder="Enter quantity"
                    />
                    {formik.errors.event_ticket_pricings?.[index]
                      ?.ticket_total_quantity && (
                      <div className="text-red-500 text-sm mt-1">
                        {
                          formik.errors.event_ticket_pricings[index]
                            .ticket_total_quantity
                        }
                      </div>
                    )}
                  </div>

                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => handleRemoveTicketType(index)}
                      className="px-4 py-2 text-sm bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center gap-2 h-10 mt-8"
                    >
                      <Trash2 size={16} />
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>

          {/* <!----- Modal ------> */}
          <Modal key={modalKey} isOpen={!!activeModal} onClose={closeModal}>
            {activeModal === "venue" && (
              <EventVenueDialog
                onClose={closeModal}
                onAddSuccess={() => {
                  // Refresh dropdown list
                  fetchDropdownData();
                }}
              />
            )}

            {activeModal === "category" && (
              <EventCategoryDialog
                onClose={closeModal}
                onAddSuccess={() => {
                  fetchDropdownData();
                  closeModal();
                }}
              />
            )}

            {activeModal === "artist" && (
              <AtristDialog
                onClose={closeModal}
                onAddSuccess={() => {
                  fetchDropdownData();
                  closeModal();
                }}
              />
            )}

            {activeModal === "ticketType" && (
              <TicketTypeDialog
                onClose={closeModal}
                onAddSuccess={() => {
                  fetchDropdownData();
                  closeModal();
                }}
              />
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
