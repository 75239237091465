import { Eye, MoreVertical, Pencil, Trash2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import DateFormatter from "../utils/date_formatter";
import { useNavigate } from "react-router-dom";
import ROUTES from "../config/routes";
import DeleteModal from "./delete_modal";

function EventTable({ eventList, children }) {
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingEventCategory, setDeletingEventCategory] = useState(null);

  const menuRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (clientId) => {
    setOpenMenuId(openMenuId === clientId ? null : clientId);
  };

  const viewEventDetails = (event) => {
    navigate(ROUTES.EVENTS.VIEW.replace(":id", event.id));
  };

  const editEventDetails = (event) => {
    navigate(ROUTES.EVENTS.EDIT.replace(":id", event.id));
  };

  const handleDeleteClick = (cat) => {
    setDeletingEventCategory(cat);
  };

  const handleCloseModal = () => {
    setDeletingEventCategory(null);
  };

  const confirmDelete = async () => {};

  return (
    <>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50 border-y border-gray-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                S.NO.
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                NAME
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                EVENT START DATE
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                EVENT END DATE
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                TICKET LIVE START DATE
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                TICKET LIVE END DATE
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                EVENT STATUS
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                PUBLICATION STATUS
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                ACTIONS
              </th>
            </tr>
          </thead>
          {eventList?.length > 0 ? (
            <tbody className="divide-y divide-gray-200">
              {eventList?.map((event, index) => (
                <tr key={event.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {event.name || "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {DateFormatter?.formatFullDate(event.event_start_date) ||
                      "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {DateFormatter?.formatFullDate(event.event_end_date) ||
                      "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {DateFormatter?.formatFullDate(
                      event.ticket_live_start_date
                    ) || "--"}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {DateFormatter?.formatFullDate(
                      event.ticket_live_end_date
                    ) || "--"}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        event?.event_status === "upcoming"
                          ? "text-purple-600 bg-purple-100"
                          : event.event_status === "live"
                          ? "text-green-600 bg-green-100"
                          : "text-red-600 bg-red-100"
                      }`}
                    >
                      {event?.event_status === "upcoming"
                        ? "Upcoming"
                        : event?.event_status === "live"
                        ? "Live"
                        : "Expired"}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        event?.is_published
                          ? "text-blue-600 bg-blue-100" // Blue for published
                          : "text-yellow-600 bg-yellow-100" // Yellow for unpublished
                      }`}
                    >
                      {event?.is_published ? "Published" : "Unpublished"}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center relative">
                      <button
                        onClick={() => toggleMenu(event?.id)}
                        className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                      >
                        <MoreVertical className="h-5 w-5" />
                      </button>

                      {openMenuId === event?.id && (
                        <div
                          className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                          style={{
                            transform: "translateY(calc(-50% + 2.5rem))",
                            top: "50%",
                          }}
                          ref={menuRef}
                        >
                          <div className="py-1">
                            <button
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                              onClick={() => viewEventDetails(event)}
                            >
                              <Eye className="h-4 w-4 mr-2" />
                              View
                            </button>
                            <button
                              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                              onClick={() => editEventDetails(event)}
                            >
                              <Pencil className="h-4 w-4 mr-2" />
                              Edit
                            </button>
                            <button
                              className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                              onClick={() => handleDeleteClick(event)}
                            >
                              <Trash2 className="h-4 w-4 mr-2" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan="7"
                  className="px-6 py-8 text-sm text-gray-500 text-center"
                >
                  No event found
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {/* <!---- Pagination passed as children---> */}
        {eventList?.length > 0 && children}
      </div>

      <div className="z-50">
        <DeleteModal
          title={"Are you sure you want to delete this event category?"}
          isOpen={deletingEventCategory}
          onCancel={handleCloseModal}
          onConfirm={confirmDelete}
        />
      </div>
    </>
  );
}

export default EventTable;
