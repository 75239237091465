import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../components/loader";
import { getAdminDashboardDetails } from "../../network_calls/dashboard_api";
import DateFormatter from "../../utils/date_formatter";
import useAuthData from "../../hooks/use_auth_data";
import { ADMIN, ORGANIZATION_STAFF } from "../../utils/constants";

const AdminDashboard = () => {
  const [dashboardResult, setDashboardResult] = useState({});
  const [loginResult] = useAuthData();

  const stats = [
    {
      title: "Total Active Events",
      value: dashboardResult?.total_active_events?.toString() || "0",
    },
    {
      title: "Total Tickets Sold",
      value: dashboardResult?.total_tickets_sold?.toString() || "0",
    },
    {
      title: "Total Employees",
      value: dashboardResult?.total_organizers?.toString() || "0",
    },
    ...(loginResult?.role === ADMIN
      ? [
          {
            title: "Total Organizations",
            value: dashboardResult?.total_organizations?.toString() || "0",
          },
        ]
      : []),
    {
      title: "Total Revenue",
      value: `₹ ${dashboardResult?.total_revenue || "0.00"}`,
    },
    {
      title: "New Users (in last 30 days)",
      value: dashboardResult?.new_users?.toString() || "0",
    },
    {
      title: "Total artists",
      value: dashboardResult?.total_artists?.toString() || "0",
    },
  ];

  useEffect(() => {
    if (loginResult?.role != null && loginResult?.role !== ORGANIZATION_STAFF) {
      fetchAdminDashboardDetails();
    }
  }, [loginResult]);

  const fetchAdminDashboardDetails = async () => {
    try {
      loader(true);
      const response = await getAdminDashboardDetails();
      setDashboardResult(response.result);
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };
  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Dashboard</h1>
          <p className="text-sm text-gray-500">
            Centralised Overview of Key Metrics and Insights
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
          {stats.map((stat, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm">
              <div className="p-4">
                <h3 className="text-sm font-medium text-gray-500 mb-2">
                  {stat.title}
                </h3>
                <p className="text-2xl font-semibold text-gray-900">
                  {stat.value}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* Events Metrics */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Event Metrics
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Essential Events Metrics and Insights
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>EVENT NAME</th>
                  <th>ORGANIZER NAME</th>
                  <th>VENUE NAME</th>
                  <th>START DATE</th>
                  <th>END DATE</th>
                  <th>TOTAL ORDERS</th>
                  <th>TOTAL TICKETS SOLD</th>
                </tr>
              </thead>
              {dashboardResult?.event_metrics &&
              dashboardResult?.event_metrics.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.event_metrics.map((eventMetrics, index) => (
                    <tr
                      key={index}
                      className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                    >
                      <td>{index + 1}</td>
                      <td>{eventMetrics.event_name}</td>
                      <td>{eventMetrics.organizer_name}</td>
                      <td>{eventMetrics.venue_name}</td>
                      <td>
                        {DateFormatter.formatFullDate(
                          eventMetrics.event_start_date
                        )}
                      </td>
                      <td>
                        {DateFormatter.formatFullDate(
                          eventMetrics.event_end_date
                        )}
                      </td>
                      <td>{eventMetrics.total_orders}</td>
                      <td>{eventMetrics.total_tickets_sold}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No event metrics found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

        {/* Organizations Metrics */}
        {loginResult?.role === ADMIN && (
          <div className="bg-white rounded-lg shadow-sm mb-4">
            <div className="p-4">
              <h1 className="text-2xl font-semibold text-gray-800">
                Organizations Metrics
              </h1>
              <p className="text-sm text-gray-500">
                Comprehensive Dashboard of Essential Organizations Metrics and
                Insights
              </p>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50 border-y border-gray-200">
                  <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                    <th>S.NO.</th>
                    <th>ORGANIZER NAME</th>
                    <th>ORGANIZER EMAIL</th>
                    <th>ORGANIZER CONTACT</th>
                    <th>TOTAL EVENTS HOSTED</th>
                    <th>TOTAL REVENUE</th>
                  </tr>
                </thead>
                {dashboardResult?.organizations_metrics &&
                dashboardResult?.organizations_metrics.length > 0 ? (
                  <tbody className="divide-y divide-gray-200">
                    {dashboardResult.organizations_metrics.map(
                      (organizationsMetrics, index) => (
                        <tr
                          key={index}
                          className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                        >
                          <td>{index + 1}</td>
                          <td>{organizationsMetrics.organizer_name}</td>
                          <td>{organizationsMetrics.organizer_email}</td>
                          <td>{organizationsMetrics.organizer_contact}</td>
                          <td>{organizationsMetrics.total_events_hosted}</td>
                          <td>₹ {organizationsMetrics.total_revenue}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td
                        colSpan="7"
                        className="px-6 py-8 text-sm text-gray-500 text-center"
                      >
                        No organization metrics found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        )}

        {/* Top Event Categories */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Top Event Categories Metrics
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Essential Top Event Categories Metrics
              and Insights
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>CATEGORY NAME</th>
                  <th>TOTAL EVENTS</th>
                  <th>TOTAL ORDERS</th>
                  <th>TOTAL TICKETS SOLD</th>
                  <th>TOTAL REVENUE</th>
                </tr>
              </thead>
              {dashboardResult?.top_event_categories &&
              dashboardResult?.top_event_categories.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.top_event_categories.map(
                    (topEventCategories, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>{topEventCategories.category_name}</td>
                        <td>{topEventCategories.total_events}</td>
                        <td>{topEventCategories.total_orders}</td>
                        <td>{topEventCategories.total_tickets_sold}</td>
                        <td>₹ {topEventCategories.total_revenue}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No top event categories found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* Venue Performance Metrics */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Venue Performance Metrics
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Essential Venue Performance Metrics and
              Insights
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>VENUE NAME</th>
                  <th>ADDRESS</th>
                  <th>TOTAL EVENTS HOSTED</th>
                  <th>TOTAL ORDERS</th>
                  <th>TOTAL TICKETS SOLD</th>
                  <th>TOTAL REVENUE</th>
                  <th>AVG ORDER VALUE</th>
                </tr>
              </thead>
              {dashboardResult?.venue_performance_metrics &&
              dashboardResult?.venue_performance_metrics.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.venue_performance_metrics.map(
                    (venuePerformanceMetrics, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>{venuePerformanceMetrics.venue_name}</td>
                        <td>{venuePerformanceMetrics.address}</td>
                        <td>{venuePerformanceMetrics.total_events_hosted}</td>
                        <td>{venuePerformanceMetrics.total_orders}</td>
                        <td>{venuePerformanceMetrics.total_tickets_sold}</td>
                        <td>₹ {venuePerformanceMetrics.total_revenue}</td>
                        <td>₹ {venuePerformanceMetrics.average_order_value}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No venue performance metrics found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* Event Scan Metrics */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Event Scan Metrics
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Essential Event Scan Metrics and
              Insights
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>EVENT NAME</th>
                  <th>ORGANIZER NAME</th>
                  <th>TOTAL SCANS</th>
                  <th>UNIQUE TICKETS SCANNED</th>
                  <th>ENTRY COUNT</th>
                  <th>EXIT COUNT</th>
                  <th>REENTRY COUNT</th>
                  <th>TOTAL SCANNERS</th>
                </tr>
              </thead>
              {dashboardResult?.event_scan_metrics &&
              dashboardResult?.event_scan_metrics.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.event_scan_metrics.map(
                    (eventScanMetrics, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>{eventScanMetrics.event_name}</td>
                        <td>{eventScanMetrics.organizer_name}</td>
                        <td>{eventScanMetrics.total_scans}</td>
                        <td>{eventScanMetrics.unique_tickets_scanned}</td>
                        <td>{eventScanMetrics.entry_count}</td>
                        <td>{eventScanMetrics.exit_count}</td>
                        <td>{eventScanMetrics.reentry_count}</td>
                        <td>{eventScanMetrics.total_scanners}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No event scan metrics found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* Event Ticket Metrics */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Event Ticket Metrics
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Essential Event Ticket Metrics and
              Insights
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>EVENT NAME</th>
                  <th>TICKET TYPE</th>
                  <th>TICKET CATEGORY</th>
                  <th>TICKETS SOLD</th>
                  <th>TOTAL REVENUE</th>
                  <th>REFUND REQUESTS</th>
                </tr>
              </thead>
              {dashboardResult?.event_ticket_metrics &&
              dashboardResult?.event_ticket_metrics.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.event_ticket_metrics.map(
                    (eventTicketMetrics, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>{eventTicketMetrics.event_name}</td>
                        <td>{eventTicketMetrics.ticket_type}</td>
                        <td>{eventTicketMetrics.ticket_category}</td>
                        <td>{eventTicketMetrics.tickets_sold}</td>
                        <td>₹ {eventTicketMetrics.total_revenue}</td>
                        <td>{eventTicketMetrics.refund_requests}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No event ticket metrics found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* Ticket Sales Trends */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Ticket Sales Trends
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Ticket Sales Trends
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>SALE DATE</th>
                  <th>TICKET CATEGORY</th>
                  <th>TOTAL ORDERS</th>
                  <th>TICKETS SOLD</th>
                  <th>DAILY REVENUE</th>
                  <th>UNIQUE BUYERS</th>
                  <th>AVG TICKETS PER ORDER</th>
                  <th>RESALE TICKETS</th>
                </tr>
              </thead>
              {dashboardResult?.ticket_sales_trends &&
              dashboardResult?.ticket_sales_trends.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.ticket_sales_trends.map(
                    (ticketSalesTrends, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>
                          {DateFormatter.formatFullDate(
                            ticketSalesTrends.sale_date
                          )}
                        </td>
                        <td>{ticketSalesTrends.ticket_category}</td>
                        <td>{ticketSalesTrends.total_orders}</td>
                        <td>{ticketSalesTrends.tickets_sold}</td>
                        <td>₹ {ticketSalesTrends.daily_revenue}</td>
                        <td>{ticketSalesTrends.unique_buyers}</td>
                        <td>₹ {ticketSalesTrends.avg_tickets_per_order}</td>
                        <td>{ticketSalesTrends.resale_tickets}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No ticket sales trends found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {/* Customer Purchase Analysis */}
        <div className="bg-white rounded-lg shadow-sm mb-4">
          <div className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800">
              Customer Purchase Analysis
            </h1>
            <p className="text-sm text-gray-500">
              Comprehensive Dashboard of Customer Purchase Analysis
            </p>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr className="*:px-6 *:py-3 *:text-left *:text-sm *:font-medium *:text-gray-500">
                  <th>S.NO.</th>
                  <th>USER ID</th>
                  <th>TOTAL ORDERS</th>
                  <th>UNIQUE EVENTS ATTENDED</th>
                  <th>TOTAL TICKETS PURCHASED</th>
                  <th>TOTAL SPENT</th>
                  <th>AVG ORDER VALUE</th>
                  <th>LAST PURCHASE DATE</th>
                  <th>TICKET CATEGORIES BOUGHT</th>
                </tr>
              </thead>
              {dashboardResult?.customer_purchase_analysis &&
              dashboardResult?.customer_purchase_analysis.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {dashboardResult.customer_purchase_analysis.map(
                    (customerPurchaseAnalysis, index) => (
                      <tr
                        key={index}
                        className="*:px-6 *:py-4 *:text-sm *:text-gray-500"
                      >
                        <td>{index + 1}</td>
                        <td>{customerPurchaseAnalysis.user_id}</td>
                        <td>{customerPurchaseAnalysis.total_orders}</td>
                        <td>
                          {customerPurchaseAnalysis.unique_events_attended}
                        </td>
                        <td>
                          {customerPurchaseAnalysis.total_tickets_purchased}
                        </td>
                        <td>₹ {customerPurchaseAnalysis.total_spent}</td>
                        <td>₹ {customerPurchaseAnalysis.avg_order_value}</td>
                        <td>
                          {DateFormatter.formatFullDate(
                            customerPurchaseAnalysis.last_purchase_date
                          )}
                        </td>
                        <td>
                          {customerPurchaseAnalysis.ticket_categories_bought}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No customer purchase analysis found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/*<!-- End block -->*/}
    </div>
  );
};

export default AdminDashboard;
