import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Download,
  MoreVertical,
  Pencil,
  Trash2,
  Eye,
  Filter,
  Plus,
  X,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import TablePagination from "../../components/table_pagination";
import { useFormik } from "formik";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getAllTicketType } from "../../network_calls/event_api";
import FilterActionsFooter from "../../components/filter_actions_footer";
import { getAllOrganizations } from "../../network_calls/organization_api";
import DateFormatter from "../../utils/date_formatter";
import { sortAlphabetically } from "../../utils/helper";
import useAuthData from "../../hooks/use_auth_data";
import { ADMIN } from "../../utils/constants";

const TicketTypeList = () => {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState(null);
  const [deletingTicketType, setDeletingTicketType] = useState(null);
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [ticketTypeListResult, setTicketTypeListResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const [filters, setFilters] = useState({
    organization_id: "",
    ticket_category: "",
  });
  const [loginResult] = useAuthData();

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await getAllOrganizations({
          is_active: true,
        });
        const alphabeticallySortedResult = sortAlphabetically(response.result);
        setOrganizationList(alphabeticallySortedResult || []);
      } catch (error) {
        if (error.code !== 404) {
          toast.error("Failed to fetch organizations");
        }
      }
    };
    if (loginResult?.role === ADMIN) fetchOrganizations();
  }, [loginResult]);

  const toggleMenu = (clientId) => {
    setOpenMenuId(openMenuId === clientId ? null : clientId);
  };

  const confirmDelete = async () => {};

  const handleDeleteClick = (eventVenue) => {
    setDeletingTicketType(eventVenue);
  };

  const handleCloseModal = () => {
    setDeletingTicketType(null);
  };

  const handleFilter = async () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTicketTypeList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, itemsPerPage, searchTerm, filters, sortConfig]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleFilterChange = (filterData) => {
    setFilters(filterData);
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleResetFilters = () => {
    formik.resetForm();
    handleFilterChange({
      organization_id: "",
      ticket_category: "",
    });
    handleFilter();
  };

  const handleSort = async (key) => {
    let newOrder = "asc";

    if (sortConfig.key === key) {
      if (sortConfig.order === "asc") {
        newOrder = "desc";
      } else if (sortConfig.order === "desc") {
        // Reset sorting
        key = "";
        newOrder = "";
      }
    }

    setSortConfig({
      key: key,
      order: newOrder,
    });
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="inline ml-2 h-4 w-4" />;
    }
    return sortConfig.order === "asc" ? (
      <ArrowUp className="inline ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="inline ml-2 h-4 w-4" />
    );
  };

  const formik = useFormik({
    initialValues: {
      organization_id: "",
      ticket_category: "",
    },
    validate: (values) => {
      const errors = {};
      return errors;
    },
    onSubmit: (values) => {
      handleFilterChange({
        organization_id: values.organization_id,
        ticket_category: values.ticket_category,
      });
      handleFilter();
    },
  });

  const getTicketTypeList = async () => {
    try {
      loader(true);
      const response = await getAllTicketType({
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        organization_id: filters.organization_id,
        ticket_category: filters.ticket_category,
        sort_key: sortConfig.key,
        sort_order: sortConfig.order,
      });
      setTotalCount(response.pagination.total);
      setTicketTypeListResult(response.result);
      toast.success("List fetched successfully");
    } catch (error) {
      if (error.code === 404) {
        setTotalCount(0);
        setTicketTypeListResult([]);
      } else {
        // Show error toast for other errors
        toast.error(error.message || "Operation failed");
      }
    } finally {
      loader(false);
    }
  };

  const addTicketType = () => {
    navigate(ROUTES.TICKET_TYPES.CREATE);
  };

  const viewTicketTypeDetails = (ticketType) => {
    navigate(ROUTES.TICKET_TYPES.VIEW.replace(":id", ticketType.id));
  };

  const editTicketTypeDetails = (ticketType) => {
    navigate(ROUTES.TICKET_TYPES.EDIT.replace(":id", ticketType.id));
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Ticket Types</h1>
          <p className="text-sm text-gray-500">Ticket Control - Ticket Types</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleFilter}
                className="px-4 py-2 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 flex items-center gap-2 text-sm"
              >
                <Filter className="w-5 h-5" />
                Filter
              </button>
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => addTicketType()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
              >
                <Plus className="w-5 h-5" />
                Add Ticket Type
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-y border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    S.NO.
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center">
                      NAME
                      {getSortIcon("name")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    DESCRIPTION
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    TICKET CATEGORY
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ORGANISATION
                  </th>
                  <th
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 cursor-pointer select-none"
                    onClick={() => handleSort("created_at")}
                  >
                    <div className="flex items-center">
                      CREATED AT
                      {getSortIcon("created_at")}
                    </div>
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              {ticketTypeListResult.length > 0 ? (
                <tbody className="divide-y divide-gray-200">
                  {ticketTypeListResult.map((ticketType, index) => (
                    <tr key={ticketType.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {index + (currentPage - 1) * itemsPerPage + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {ticketType.name || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        <div className="line-clamp-4">
                          {ticketType.description || "--"}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {ticketType.ticket_category || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {ticketType.organization_name || "--"}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {DateFormatter.formatFullDate(ticketType.created_at)}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex justify-center relative">
                          <button
                            onClick={() => toggleMenu(ticketType.id)}
                            className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
                          >
                            <MoreVertical className="h-5 w-5" />
                          </button>

                          {openMenuId === ticketType.id && (
                            <div
                              className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                              style={{
                                transform: "translateY(calc(-50% + 2.5rem))",
                                top: "50%",
                              }}
                              ref={menuRef}
                            >
                              <div className="py-1">
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() =>
                                    viewTicketTypeDetails(ticketType)
                                  }
                                >
                                  <Eye className="h-4 w-4 mr-2" />
                                  View
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                                  onClick={() =>
                                    editTicketTypeDetails(ticketType)
                                  }
                                >
                                  <Pencil className="h-4 w-4 mr-2" />
                                  Edit
                                </button>
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full"
                                  onClick={() => handleDeleteClick(ticketType)}
                                >
                                  <Trash2 className="h-4 w-4 mr-2" />
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-8 text-sm text-gray-500 text-center"
                    >
                      No ticket type found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {/* Pagination */}
          {ticketTypeListResult.length > 0 && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={itemsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(newPageSize) => {
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this ticket type?"}
        isOpen={deletingTicketType}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
      {/* <!-- Drawer Filter-->*/}
      {isFilterDrawerOpen ? (
        <>
          <div
            className="fixed top-0 left-0 z-50 w-full h-screen bg-black opacity-50"
            onClick={handleFilter}
          />
          <div
            className={`fixed top-0 right-0 z-50 w-full h-screen max-w-sm bg-white flex flex-col transition-transform ${
              isFilterDrawerOpen ? "translate-x-0" : "translate-x-full"
            }`}
            tabIndex="-1"
            aria-labelledby="drawer-label"
          >
            {/* Fixed Header */}
            <div className="px-4 py-3 border-b flex items-center justify-between">
              <h5 className="text-sm font-semibold text-gray-500 uppercase">
                Filter List
              </h5>
              <button
                onClick={handleFilter}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              >
                <X className="w-5 h-5" />
                <span className="sr-only">Close menu</span>
              </button>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col h-full"
            >
              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto p-4 mb-28">
                <div className="space-y-4">
                  {loginResult?.role === ADMIN && (
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Organization
                      </label>
                      <select
                        name="organization_id"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                        onChange={formik.handleChange}
                        value={formik.values.organization_id}
                      >
                        <option value="">All Organizations</option>
                        {organizationList.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Ticket Category
                    </label>
                    <select
                      name="ticket_category"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.ticket_category}
                    >
                      <option value="">All Categories</option>
                      <option value="vip">VIP</option>
                      <option value="general">General</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Fixed Footer */}
              <div className="border-t p-4 bg-white">
                <FilterActionsFooter onReset={handleResetFilters} />
              </div>
            </form>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TicketTypeList;
