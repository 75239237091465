import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { login, verifyOtp } from "../../network_calls/auth_api";
import { KEY_LOGIN_RESULT } from "../../utils/constants";
import loader from "../../components/loader";
import ROUTES from "../../config/routes";

const Login = () => {
  const navigate = useNavigate();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [canResend, setCanResend] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    let loginResult = localStorage.getItem(KEY_LOGIN_RESULT);
    if (loginResult) {
      try {
        loginResult = JSON.parse(loginResult);
        if (loginResult && loginResult.token) {
          navigate(ROUTES.DASHBOARD);
        }
      } catch (error) {
        localStorage.removeItem(KEY_LOGIN_RESULT);
      }
    }
  }, [navigate]);

  // Timer logic for OTP expiry countdown
  useEffect(() => {
    if (isOtpSent && timer > 0) {
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerRef.current);
            setCanResend(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [isOtpSent, timer]);

  // Format time from seconds to MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleResendOtp = async () => {
    try {
      loader(true);
      const response = await login({
        mobile_number: formik.values.mobile_number,
        platform: "web",
      });
      toast.success(response.message || "OTP resent successfully");
      // Reset timer and disable resend
      setTimer(300);
      setCanResend(false);
    } catch (error) {
      toast.error(error.message || "Failed to resend OTP");
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      otp: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.mobile_number) {
        errors.mobile_number = "Mobile number is required";
      } else if (!/^\d{10}$/.test(values.mobile_number)) {
        errors.mobile_number = "Mobile number must be exactly 10 digits";
      }
      if (isOtpSent && !values.otp) {
        errors.otp = "OTP is required";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        loader(true);
        if (!isOtpSent) {
          // Send OTP
          const response = await login({
            mobile_number: values.mobile_number,
            platform: "web",
          });
          toast.success(response.message || "OTP sent successfully");
          setIsOtpSent(true);
          setPhoneNumber(values.mobile_number);
          setTimer(300); // Reset timer to 5 minutes
          setCanResend(false);
        } else {
          // Verify OTP
          const response = await verifyOtp({
            mobile_number: values.mobile_number,
            otp: values.otp,
            platform: "web",
          });
          const loginResult = response.result;
          toast.success(response.message);
          localStorage.setItem(KEY_LOGIN_RESULT, JSON.stringify(loginResult));
          navigate(ROUTES.DASHBOARD);
        }
      } catch (error) {
        toast.error(error.message || "Operation failed");
      } finally {
        loader(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="min-h-screen bg-slate-50 flex">
      {/* Left side with illustration */}
      <div className="flex-1 flex items-center justify-center p-8">
        <div className="relative w-full max-w-md">
          <div className="mt-16">
            <img
              src="assets/img/login_bg.svg"
              alt="Login"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      {/* Right side with login form */}
      <div className="flex-1 flex items-center justify-center p-8 bg-white">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center space-y-3">
            <div className="flex justify-center">
              <div className="h-16 w-16 bg-blue-100 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-blue-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a8 8 0 100 16 8 8 0 000-16zM5.172 9.004a4 4 0 017.16 2.369 4 4 0 01-2.868 3.851l-.334.11a.75.75 0 01-.3-1.47l.335-.11a2.5 2.5 0 001.78-2.38 2.5 2.5 0 00-4.478-1.482.75.75 0 01-1.295-.754zm1.596 5.626a.75.75 0 000 1.06l.53.53a.75.75 0 001.06 0l.53-.53a.75.75 0 00-1.06-1.06l-.53.53-.53-.53a.75.75 0 00-1.06 1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <h1 className="text-3xl font-bold text-gray-900">
              Welcome to Wooziee
            </h1>
            <p className="text-gray-600">Your Admin Dashboard</p>
          </div>

          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
            <div className="space-y-6">
              {/* Login Form */}
              <form onSubmit={formik.handleSubmit} className="space-y-5">
                <div>
                  <label
                    htmlFor="mobile_number"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Mobile Number
                  </label>
                  <div className="flex">
                    <div className="flex items-center justify-center px-3 bg-gray-50 border border-gray-300 border-r-0 rounded-l-lg">
                      <span className="text-gray-500 text-sm font-medium">
                        +91
                      </span>
                    </div>
                    <input
                      type="text"
                      id="mobile_number"
                      name="mobile_number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile_number}
                      disabled={isOtpSent}
                      placeholder="Enter your 10-digit number"
                      className="flex-1 block w-full px-4 py-3 bg-white border border-gray-300 rounded-r-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50 disabled:text-gray-500"
                    />
                  </div>
                  {formik.touched.mobile_number &&
                    formik.errors.mobile_number && (
                      <div className="text-red-500 text-sm mt-1">
                        {formik.errors.mobile_number}
                      </div>
                    )}
                </div>

                {isOtpSent && (
                  <>
                    <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-blue-600"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-blue-700 font-medium">
                            An SMS with 6-digit OTP has been sent to +91
                            {phoneNumber}
                          </p>
                          <p className="text-sm text-blue-700 mt-1">
                            OTP will expire in {formatTime(timer)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="otp"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Enter OTP
                      </label>
                      <input
                        type="text"
                        id="otp"
                        name="otp"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.otp}
                        placeholder="Enter 6-digit OTP"
                        className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        maxLength={6}
                      />
                      {formik.touched.otp && formik.errors.otp && (
                        <div className="text-red-500 text-sm mt-1">
                          {formik.errors.otp}
                        </div>
                      )}
                      <div className="mt-2 text-right">
                        {canResend ? (
                          <button
                            type="button"
                            onClick={handleResendOtp}
                            className="text-blue-600 text-sm font-medium hover:text-blue-800 focus:outline-none"
                          >
                            Resend OTP
                          </button>
                        ) : (
                          <span className="text-gray-500 text-sm">
                            Resend OTP in {formatTime(timer)}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors duration-200"
                >
                  {formik.isSubmitting ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </span>
                  ) : isOtpSent ? (
                    "Verify OTP"
                  ) : (
                    "Send OTP"
                  )}
                </button>
              </form>

              {!isOtpSent && (
                <div className="mt-4 text-center">
                  <p className="text-sm text-gray-600">
                    We'll send you a one-time password to verify your number
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">
              Having trouble logging in?{" "}
              <a
                href="mailto:team@wooziee.com"
                className="text-blue-600 font-medium hover:text-blue-800"
              >
                Contact Support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
