import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideNav from "./side_nav";
import MainContent from "./main_content";
import useAuthData from "../hooks/use_auth_data";
import { ORGANIZATION_ADMIN, ORGANIZATION_STAFF } from "../utils/constants";
import ROUTES from "../config/routes";

// Layout Component
const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loginResult] = useAuthData();
  const naviagte = useNavigate();

  // If user role is `ORGANIZATION_STAFF` then accesing pathname "/" will automatically redirect to "/user"
  useEffect(() => {
    if (
      loginResult?.role === ORGANIZATION_STAFF &&
      window.location.pathname === "/"
    ) {
      naviagte(ROUTES.USERS.LIST, {
        replace: true,
      });
    }
  }, [loginResult?.role, naviagte]);

  return (
    <div className="flex h-screen bg-slate-50">
      {/* Sidebar */}
      <SideNav isSidebarOpen={isSidebarOpen} />

      {/* Main Content */}
      <MainContent
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        loginResult={loginResult}
      >
        <Outlet />
      </MainContent>
    </div>
  );
};

export default MainLayout;
