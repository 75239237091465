import React, { useState, useEffect } from "react";
import { ChevronDown } from "lucide-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import loader from "../components/loader";
import { addArtist, getAllGenres } from "../network_calls/event_api";
import { sortAlphabetically } from "../utils/helper";
import MultiselectDropdown from "../components/multi_select_dropdown";

function AtristDialog({ onClose, onAddSuccess }) {
  const [genres, setGenres] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    fetch("/assets/json/country.json")
      .then((response) => response.json())
      .then((data) => {
        setCountryData(data.countries);
      })
      .catch((error) => {
        console.error("Error loading country data:", error);
      });
    fetchGenres();
  }, []);

  const fetchGenres = async () => {
    try {
      const response = await getAllGenres();
      if (response.code === 200) {
        const alphabeticallySortedGenres = sortAlphabetically(response.result);
        setGenres(alphabeticallySortedGenres);
      }
    } catch (error) {
      toast.error("Failed to fetch genres");
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    formik.setFieldValue("country", e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      bio: "",
      date_of_birth: "",
      country: "",
      genre_id: [], // Using an Array to store mutiple `genre_id` values
      is_active: 1,
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (values.name && values.name.length < 2) {
        errors.name = "Name must be at least 2 characters long";
      }

      if (!values.genre_id.length > 0) {
        errors.genre_id = "Genre is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        // Transform the data before sending to API
        const formData = {
          ...values,
          date_of_birth: values.date_of_birth || null,
        };
        const response = await addArtist(formData);
        if (response.code === 200) {
          toast.success("Artist added successfully");
          onAddSuccess(); // to fetch dropdown list again in parent
          onClose(); // to close modal
        } else {
          toast.error("Failed to add artist");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">Add Artist</h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Name */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter artist name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.name}
                </div>
              )}
            </div>

            {/* Date of Birth */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Date of Birth
              </label>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date_of_birth}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Country Dropdown */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Country
              </label>
              <select
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                onBlur={formik.handleBlur}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select Country</option>
                {countryData.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Active Status */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <div className="relative">
                <select
                  id="is_active"
                  name="is_active"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.is_active}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
                <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Genre <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <MultiselectDropdown
                  formik={formik}
                  options={genres}
                  formikValue="genre_id"
                  placeholder="Select Genre"
                  className="w-full px-4 py-2 pr-10 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
              </div>
              {formik.touched.genre_id && formik.errors.genre_id && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.genre_id}
                </div>
              )}
            </div>
          </div>

          {/* Bio */}
          <div className="space-y-2 mb-6">
            <label className="block text-sm font-medium text-gray-700">
              Bio
            </label>
            <textarea
              id="bio"
              name="bio"
              placeholder="Enter artist bio..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bio}
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AtristDialog;
