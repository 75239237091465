import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  User,
  Mail,
  Phone,
  CheckCircle,
  XCircle,
  Pencil,
  Trash,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import { getOrganizationDetails } from "../../network_calls/organization_api";
import ROUTES from "../../config/routes";
import { getAllUsers } from "../../network_calls/user_api";
import UserTable from "../../components/user_table";
import TablePagination from "../../components/table_pagination";

const ViewOrganizationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [organizationResult, setOrganizationResult] = useState({});
  const [deletingOrganization, setDeletingOrganization] = useState(null);
  const [activeTab, setActiveTab] = useState("organization_details");
  const [totalCount, setTotalCount] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    order: "",
  });
  const [lastFetchParams, setLastFetchParams] = useState(null);

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  const handleDeleteClick = (organization) => {
    setDeletingOrganization(organization);
  };

  const handleCloseModal = () => {
    setDeletingOrganization(null);
  };

  const confirmDelete = async () => {};

  const redirectToOrganizations = () => {
    navigate(ROUTES.ORGANIZATIONS.LIST);
  };

  const openEditOrganization = (organization) => {
    navigate(ROUTES.ORGANIZATIONS.EDIT.replace(":id", organization.id));
  };

  const fetchOrganizationDetails = async () => {
    try {
      loader(true);
      const response = await getOrganizationDetails(id);
      setOrganizationResult(response.result);
      toast.success("Organization Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  // Fetches user list with pagination and sorting
  const fetchUserListDetails = useCallback(
    async (sortingConfig = sortConfig) => {
      const requestParams = {
        page: currentPage,
        limit: itemsPerPage,
        organization_id: id,
        sort_key: sortingConfig.key,
        sort_order: sortingConfig.order,
      };

      // Prevents redundant API calls for the same parameters
      try {
        if (JSON.stringify(requestParams) !== JSON.stringify(lastFetchParams)) {
          loader(true);
          const response = await getAllUsers(requestParams);
          setTotalCount(response.pagination.total);
          setUserListData(response);
          setLastFetchParams(requestParams);
          toast.success("User List fetched successfully");
        }
      } catch (error) {
        toast.error(error.message || "Operation failed");
      } finally {
        loader(false);
      }
    },
    [currentPage, id, itemsPerPage, lastFetchParams, sortConfig]
  );

  // Fetch user list when the active tab changes to 'user_list' or when pagination changes
  useEffect(() => {
    if (activeTab === "user_list") {
      fetchUserListDetails();
    }
  }, [currentPage, itemsPerPage, activeTab, fetchUserListDetails]);

  // Handles sorting of user data
  const handleSort = async (key) => {
    let newOrder = "asc";

    // Toggles between ascending, descending, and no sorting
    if (sortConfig.key === key) {
      if (sortConfig.order === "asc") {
        newOrder = "desc";
      } else if (sortConfig.order === "desc") {
        // Reset sorting
        key = "";
        newOrder = "";
      }
    }

    const newSortConfig = {
      key: key,
      order: newOrder,
    };
    setSortConfig(newSortConfig);
  };

  // Returns the appropriate sort icon based on the current sort state
  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return <ArrowUpDown className="inline ml-2 h-4 w-4" />;
    }
    return sortConfig.order === "asc" ? (
      <ArrowUp className="inline ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="inline ml-2 h-4 w-4" />
    );
  };

  // Handles active tab switching between 'organization_details' and 'user_list'
  const handleActiveTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Organizations
          </h1>
          <p className="text-sm text-gray-500">
            <span>Organization Control - </span>
            <span
              onClick={redirectToOrganizations}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Organizations
            </span>
            <span> / {id}</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* <!-- Tab Change Button --> */}
          <div className="flex gap-10">
            <button
              onClick={() => handleActiveTabChange("organization_details")}
              className={`text-xl font-semibold text-gray-800 mb-8 ${
                activeTab === "organization_details"
                  ? "underline underline-offset-8 decoration-primary-600"
                  : ""
              }`}
            >
              Organization details
            </button>
            <button
              onClick={() => handleActiveTabChange("user_list")}
              className={`text-xl font-semibold text-gray-800 mb-8 ${
                activeTab === "user_list"
                  ? "underline underline-offset-8 decoration-primary-600"
                  : ""
              }`}
            >
              User List
            </button>
          </div>

          {/* <!----- Organization details tab -----> */}
          {activeTab === "organization_details" && (
            <>
              <div className="flex flex-row mb-4">
                <img
                  className="w-24 h-24 rounded-full bg-gray-300"
                  src={organizationResult.logo}
                  alt="logo"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Name
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <User className="w-4 h-4 text-gray-500" />
                    <span>{organizationResult.name}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Slug
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <User className="w-4 h-4 text-gray-500" />
                    <span>{organizationResult.slug}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Email
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <Mail className="w-4 h-4 text-gray-500" />
                    <span>{organizationResult.email}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Mobile Number
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <Phone className="w-4 h-4 text-gray-500" />
                    <span>{organizationResult.mobile_number}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Organization Verified
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    {organizationResult.is_verified ? (
                      <CheckCircle className="w-4 h-4 text-green-600" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-600" />
                    )}
                    <span>
                      {organizationResult.is_verified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </p>
                </div>

                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-semibold leading-none text-gray-700">
                    Status
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        organizationResult.is_active
                          ? "text-green-600 bg-green-100"
                          : "text-red-600 bg-red-100"
                      }`}
                    >
                      {organizationResult.is_active ? "Active" : "Inactive"}
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row space-x-4 mt-8">
                <button
                  onClick={() => openEditOrganization(organizationResult)}
                  type="button"
                  className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(organizationResult)}
                  type="button"
                  className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Trash className="w-5 h-5 mr-1.5 -ml-1" />
                  Delete
                </button>
              </div>
            </>
          )}
          {/* <!----- User list tab -----> */}
          {activeTab === "user_list" && (
            <>
              <UserTable
                userListData={userListData}
                onSort={handleSort}
                onGetSortIcon={getSortIcon}
              >
                <TablePagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={itemsPerPage}
                  onPageChange={(page) => setCurrentPage(page)}
                  onPageSizeChange={(newPageSize) => {
                    setItemsPerPage(newPageSize);
                    setCurrentPage(1);
                  }}
                  pageSizeOptions={[10, 20, 50]}
                />
              </UserTable>
            </>
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this organization?"}
        isOpen={deletingOrganization}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewOrganizationDetails;
