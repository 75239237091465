import React, { useState, useRef, useEffect } from "react";
import { Bell, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  KEY_LOGIN_RESULT,
  ORGANIZATION_ADMIN,
  ORGANIZATION_STAFF,
} from "../utils/constants";
import ROUTES from "../config/routes";
import loader from "../components/loader";
import { toast } from "react-toastify";
import { logout } from "../network_calls/auth_api";

// Main Header Component
const NavBar = ({ isSidebarOpen, setIsSidebarOpen, loginResult }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const profileButtonRef = useRef(null);
  const notificationButtonRef = useRef(null);

  // Fallback data if loginResult is null
  const defaultData = {
    profile_pic: "/assets/img/avatar.png4",
  };

  // Use loginResult if available, otherwise use default data
  const userData = loginResult || defaultData;

  return (
    <div className="relative">
      <header className="bg-white border-b h-16 flex items-center justify-between px-6">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="text-gray-500 hover:text-gray-700"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <div className="flex items-center space-x-4">
          {/*<button className="p-2 text-gray-500 hover:text-gray-700">
            <Settings className="w-5 h-5" />
          </button>

          <div className="relative">
            <button
              ref={notificationButtonRef}
              onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              className="p-2 text-gray-500 hover:text-gray-700 relative"
            >
              <Bell className="w-5 h-5" />
              <span className="absolute top-0 right-0 w-2 h-2 bg-blue-600 rounded-full"></span>
            </button>
            <NotificationDropdown
              isOpen={isNotificationOpen}
              setIsOpen={setIsNotificationOpen}
              buttonRef={notificationButtonRef}
            />
          </div>*/}

          <div className="relative">
            <button
              ref={profileButtonRef}
              className="p-2"
              onClick={() => setIsProfileOpen(!isProfileOpen)}
            >
              <img
                src={userData.profile_pic || "/api/placeholder/64/64"}
                alt="Profile"
                className="w-8 h-8 rounded-full"
              />
            </button>
            <ProfileDropdown
              isOpen={isProfileOpen}
              setIsOpen={setIsProfileOpen}
              buttonRef={profileButtonRef}
              loginResult={loginResult}
            />
          </div>
        </div>
      </header>
    </div>
  );
};

// Internal NotificationDropdown Component
const NotificationDropdown = ({ isOpen, setIsOpen, buttonRef }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const notifications = [
    {
      id: 1,
      avatar: "/assets/img/avatar.png",
      title: "Roman Joined the Team!",
      message: "Congratulate him",
      type: "team",
    },
    {
      id: 2,
      avatar: "/assets/img/avatar.png",
      title: "New message received",
      message: "Salma sent you new message",
      type: "message",
    },
    {
      id: 3,
      avatar: "/assets/img/avatar.png",
      title: "New Payment received",
      message: "Check your earnings",
      type: "payment",
    },
    {
      id: 4,
      avatar: "/assets/img/avatar.png",
      title: "Jolly completed tasks",
      message: "Assign her new tasks",
      type: "task",
    },
    {
      id: 5,
      avatar: "/assets/img/avatar.png",
      title: "Roman Joined the Team!",
      message: "Congratulate him",
      type: "team",
    },
  ];

  if (!isOpen) return null;

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border p-4 z-50"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Notifications</h2>
        <span className="bg-blue-600 text-white text-sm px-3 py-1 rounded-full">
          5 new
        </span>
      </div>

      <div className="space-y-4">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="flex items-start space-x-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
          >
            <img
              src={notification.avatar}
              alt=""
              className="w-10 h-10 rounded-full"
            />
            <div>
              <h3 className="font-medium">{notification.title}</h3>
              <p className="text-gray-600 text-sm">{notification.message}</p>
            </div>
          </div>
        ))}
      </div>

      <button className="w-full text-center text-blue-500 py-2 mt-4 border border-gray-200 rounded-lg hover:bg-gray-50">
        See all Notifications
      </button>
    </div>
  );
};

// Internal ProfileDropdown Component
const ProfileDropdown = ({ isOpen, setIsOpen, buttonRef, loginResult }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Fallback data if loginResult is null
  const defaultData = {
    firstname: "Guest User",
    role: "guest",
    email: "guest@example.com",
    profile_pic: "/assets/img/avatar.png4",
  };

  // Use loginResult if available, otherwise use default data
  const userData = loginResult || defaultData;

  // Format role text
  const formatRole = (role) => {
    if (!role) return "--";
    return role.toUpperCase().replace(/_/g, " "); // Using /g to replace all underscores
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const handleLogout = async () => {
    try {
      loader(true);
      const response = await logout({
        platform: "web",
      });
      toast.success(response.message || "Logout successful");
      localStorage.removeItem(KEY_LOGIN_RESULT);
      navigate(ROUTES.LOGIN);
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  const handleMyProfileClick = () => {
    setIsOpen(false);
    navigate(ROUTES.PROFILE.VIEW);
  };
  const handleMyOrganizationClick = () => {
    setIsOpen(false);
    navigate(
      ROUTES.ORGANIZATIONS.VIEW.replace(":id", userData?.organization_id)
    );
  };

  if (!isOpen) return null;

  return (
    <div
      ref={dropdownRef}
      className="absolute right-4 top-16 w-72 bg-white rounded-lg shadow-lg border p-4 z-50"
    >
      <div className="flex items-center space-x-4 mb-6">
        <img
          src={userData.profile_pic || "/api/placeholder/64/64"}
          alt="Profile"
          className="w-16 h-16 rounded-full flex-shrink-0"
        />
        <div className="min-w-0 flex-1">
          <h3 className="text-lg font-semibold truncate">
            {userData.firstname}
          </h3>
          <p className="text-gray-600 truncate">{formatRole(userData.role)}</p>
          <p className="text-gray-500 text-sm truncate">{userData.email}</p>
        </div>
      </div>

      <div className="space-y-4">
        <button
          onClick={handleMyProfileClick}
          className="flex items-center space-x-3 w-full p-2 rounded-lg hover:bg-gray-50"
        >
          <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center">
            <Settings className="w-4 h-4 text-gray-600" />
          </div>
          <div className="text-left">
            <p className="font-medium">My Profile</p>
            <p className="text-sm text-gray-500">Account Settings</p>
          </div>
        </button>

        {(userData?.role === ORGANIZATION_ADMIN ||
          userData?.role === ORGANIZATION_STAFF) && (
          <button
            onClick={handleMyOrganizationClick}
            className="flex items-center space-x-3 w-full p-2 rounded-lg hover:bg-gray-50"
          >
            <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center">
              <Bell className="w-4 h-4 text-gray-600" />
            </div>
            <div className="text-left">
              <p className="font-medium">My Organization</p>
              <p className="text-sm text-gray-500">Organization Settings</p>
            </div>
          </button>
        )}

        <button
          onClick={handleLogout}
          className="w-full text-center text-blue-500 py-2 border border-gray-200 rounded-lg"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default NavBar;
