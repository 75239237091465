import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  getEventCategoryDetails,
  updateEventCategoryDetails,
} from "../../network_calls/event_api";

const EditEventCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchEventCategoryData();
  }, [id]);

  const fetchEventCategoryData = async () => {
    try {
      loader(true);
      const response = await getEventCategoryDetails(id);
      if (response.code === 200) {
        const eventCategoryData = response.result;
        formik.setValues({
          name: eventCategoryData.name || "",
          description: eventCategoryData.description || "",
        });
      } else {
        toast.error("Failed to fetch event category data");
        redirectToEventCategories();
      }
    } catch (error) {
      toast.error("Failed to fetch event category data");
      redirectToEventCategories();
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (values.name && values.name.length < 2) {
        errors.name = "Name must be at least 2 characters long";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const response = await updateEventCategoryDetails(id, values);
        if (response.code === 200) {
          toast.success("Event category updated successfully");
          setTimeout(() => {
            redirectToEventCategories();
          }, 500);
        } else {
          toast.error("Failed to update event category");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToEventCategories = () => {
    navigate(ROUTES.EVENTS.CATEGORIES.LIST);
  };

  const redirectBackToEventCategory = () => {
    navigate(ROUTES.EVENTS.CATEGORIES.VIEW.replace(":id", id));
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">
            Event Categories
          </h1>
          <p className="text-sm text-gray-500">
            <span>Event Control - </span>
            <span
              onClick={redirectToEventCategories}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Event Categories
            </span>
            <span> / </span>
            <span
              onClick={redirectBackToEventCategory}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              {id}
            </span>
            <span> / </span>
            <span> Edit Event Category</span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">
            Edit Event Category
          </h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter event category name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>
            </div>

            {/* Description */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter event category description..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEventCategory;
