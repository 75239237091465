import { format, parseISO } from "date-fns";
import moment from "moment";

class DateFormatter {
  /**
   * Formats a date string to full month, day, and year
   * @param {string} dateString - ISO date string
   * @returns {string} Formatted date string (e.g., "January 21, 1977")
   */
  static formatFullDate(dateString) {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, "MMMM dd, yyyy");
    } catch (error) {
      console.error("Error formatting full date:", error);
      return "";
    }
  }

  /**
   * Formats a date string to short month, day, and year
   * @param {string} dateString - ISO date string
   * @returns {string} Formatted date string (e.g., "Jan 21, 1977")
   */
  static formatShortDate(dateString) {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, "PP");
    } catch (error) {
      console.error("Error formatting short date:", error);
      return "";
    }
  }

  static formatTime(timeString) {
    if (!timeString) return "";
    try {
      // Parse the time string with moment
      const time = moment(timeString, "HH:mm:ss");

      // Format as "hh:mm A" (12-hour time with AM/PM)
      return time.format("hh:mm A");
    } catch (error) {
      console.error("Error formatting time:", error);
      return "";
    }
  }

  /**
   * Formats a date string to numeric format
   * @param {string} dateString - ISO date string
   * @returns {string} Formatted date string (e.g., "01/21/1977")
   */
  static formatNumericDate(dateString) {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, "MM/dd/yyyy");
    } catch (error) {
      console.error("Error formatting numeric date:", error);
      return "";
    }
  }

  /**
   * Formats a date string to European format
   * @param {string} dateString - ISO date string
   * @returns {string} Formatted date string (e.g., "21-01-1977")
   */
  static formatEuropeanDate(dateString) {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, "dd-MM-yyyy");
    } catch (error) {
      console.error("Error formatting European date:", error);
      return "";
    }
  }

  /**
   * Formats a date string to YYYY-MM-DD format (suitable for HTML date inputs)
   * @param {string} dateString - ISO date string
   * @returns {string} Formatted date string (e.g., "1977-01-21")
   */
  static formatYYYYMMDD(dateString) {
    if (!dateString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, "yyyy-MM-dd");
    } catch (error) {
      console.error("Error formatting YYYY-MM-DD date:", error);
      return "";
    }
  }

  /**
   * Custom format a date string
   * @param {string} dateString - ISO date string
   * @param {string} formatString - Format string (e.g., 'yyyy-MM-dd')
   * @returns {string} Formatted date string
   */
  static formatCustom(dateString, formatString) {
    if (!dateString || !formatString) return "";
    try {
      const date = parseISO(dateString);
      return format(date, formatString);
    } catch (error) {
      console.error("Error formatting custom date:", error);
      return "";
    }
  }
}

export default DateFormatter;
