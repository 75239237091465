import axiosClient from "../utils/api_client";

export function getAllUsers(param) {
  return axiosClient.get("/users", {
    params: param,
  });
}

export function getUserDetails(id) {
  return axiosClient.get(`/users/${id}`);
}

export function addUser(data) {
  return axiosClient.post("/users", data);
}

export function updateUserDetails(id, data) {
  return axiosClient.put(`/users/${id}`, data);
}

export function getOrganizationUsers(id) {
  return axiosClient.get(`/organizations/${id}/users`);
}

export function getUserAttendedEventsDetails(id) {
  return axiosClient.get(`/users/attended-events/${id}`);
}
