// Local Storage Keys
export const KEY_LOGIN_RESULT = "key_login_result_wooziee";

// User Roles
export const ORGANIZATION_ADMIN = "organizer_admin";
export const ORGANIZATION_STAFF = "organizer_staff";
export const ADMIN = "admin";

// Dropdown Options

export const durationTypeDropdownOptions = [
  { name: "Single Day", value: "single_day" },
  { name: "Multiple Days", value: "multiple_days" },
];

export const eventTypeDropdownOptions = [
  { name: "Full Day", value: "full_day" },
  { name: "Evening", value: "evening" },
  { name: "Night", value: "night" },
];

export const languageDropDownOptions = [
  { name: "English", id: "en" },
  { name: "French", id: "fr" },
  { name: "German", id: "de" },
  { name: "Spanish", id: "es" },
  { name: "Italian", id: "it" },
  { name: "Chinese", id: "zh" },
  { name: "Japanese", id: "ja" },
  { name: "Korean", id: "ko" },
  { name: "Portuguese", id: "pt" },
  { name: "Russian", id: "ru" },
  { name: "Arabic", id: "ar" },
  { name: "Hindi", id: "hi" },
  { name: "Bengali", id: "bn" },
  { name: "Malay", id: "ms" },
  { name: "Tamil", id: "ta" },
  { name: "Telugu", id: "te" },
  { name: "Urdu", id: "ur" },
  { name: "Hausa", id: "ha" },
];

export const genderDropDownOptions = ["Male", "Female", "Other"];

export const audienceTypeOptions = [
  { name: "All", value: "all" },
  { name: "Family Friendly", value: "family_friendly" },
  { name: "Adult Only", value: "adult_only" },
];
