import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Pencil, Trash } from "lucide-react";
import DeleteModal from "../../components/delete_modal";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import {
  getAllEvents,
  getTicketTypeDetails,
} from "../../network_calls/event_api";
import ROUTES from "../../config/routes";
import EventTable from "../../components/event_table";
import TablePagination from "../../components/table_pagination";

const ViewTicketTypeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticketTypeResult, setTicketTypeResult] = useState({});
  const [deletingTicketType, setDeletingTicketType] = useState(null);
  const [activeTab, setActiveTab] = useState("ticket_type_details");
  const [ticketTypeEventListResult, setTicketTypeEventListResult] = useState(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchTicketTypeDetails();
  }, []);

  const handleDeleteClick = (ticketType) => {
    setDeletingTicketType(ticketType);
  };

  const handleCloseModal = () => {
    setDeletingTicketType(null);
  };

  const confirmDelete = async () => {};

  const redirectToTicketTypes = () => {
    navigate(ROUTES.TICKET_TYPES.LIST);
  };

  const openEditTicketType = (ticketType) => {
    navigate(ROUTES.TICKET_TYPES.EDIT.replace(":id", ticketType.id));
  };

  const fetchTicketTypeDetails = async () => {
    try {
      loader(true);
      const response = await getTicketTypeDetails(id);
      setTicketTypeResult(response.result);
      toast.success("Ticket Type Detail fetched successfully");
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  const fetchTicketTypeEventListDetails = async () => {
    try {
      if (!ticketTypeEventListResult.length) {
        loader(true);
        const response = await getAllEvents({
          page: currentPage,
          limit: itemsPerPage,
          ticket_type_id: id,
        });
        setTotalCount(response.pagination.total);
        setTicketTypeEventListResult(response.result);
        toast.success("Ticket Type Event List fetched successfully");
      }
    } catch (error) {
      toast.error(error.message || "Operation failed");
    } finally {
      loader(false);
    }
  };

  // Triggers active-tab change
  const handleActiveTabChange = (tabName) => {
    if (tabName === "event_list") fetchTicketTypeEventListDetails();
    setActiveTab(tabName);
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Ticket Types</h1>
          <p className="text-sm text-gray-500">
            <span>Ticket Control - </span>
            <span
              onClick={redirectToTicketTypes}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              Ticket Types
            </span>
            <span> / {id}</span>
          </p>
        </div>

        {/* <!---- Tab Conatiner ----> */}

        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* <!-- Tab Change Button --> */}
          <div className="flex gap-10">
            <button
              onClick={() => handleActiveTabChange("ticket_type_details")}
              className={`text-xl font-semibold text-gray-800 mb-8 ${
                activeTab === "ticket_type_details"
                  ? "underline underline-offset-8 decoration-primary-600"
                  : ""
              }`}
            >
              Ticket Type details
            </button>
            <button
              onClick={() => handleActiveTabChange("event_list")}
              className={`text-xl font-semibold text-gray-800 mb-8 ${
                activeTab === "event_list"
                  ? "underline underline-offset-8 decoration-primary-600"
                  : ""
              }`}
            >
              Event List
            </button>
          </div>

          {/* <!-- Ticket Type Details Tab -->  */}
          {activeTab === "ticket_type_details" && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Name
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>{ticketTypeResult.name || "--"}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Description
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>
                      {ticketTypeResult.description ||
                        "No description available"}
                    </span>
                  </p>
                </div>

                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Ticket Category
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>{ticketTypeResult.ticket_category || "--"}</span>
                  </p>
                </div>
                <div className="w-full flex flex-col">
                  <h2 className="mb-2 font-medium leading-none text-gray-700">
                    Added by
                  </h2>
                  <p className="mb-4 font-medium text-gray-500 flex flex-row space-x-2 items-center">
                    <span>{ticketTypeResult.organization_name || "--"}</span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row space-x-4 mt-8">
                <button
                  onClick={() => openEditTicketType(ticketTypeResult)}
                  type="button"
                  className="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Pencil className="w-5 h-5 mr-1.5 -ml-1" />
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(ticketTypeResult)}
                  type="button"
                  className="inline-flex items-center text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <Trash className="w-5 h-5 mr-1.5 -ml-1" />
                  Delete
                </button>
              </div>
            </>
          )}

          {/* <!-- Event List Tab -->  */}
          {activeTab === "event_list" && (
            <EventTable eventList={ticketTypeEventListResult}>
              <TablePagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={itemsPerPage}
                onPageChange={(page) => setCurrentPage(page)}
                onPageSizeChange={(newPageSize) => {
                  setItemsPerPage(newPageSize);
                  setCurrentPage(1);
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </EventTable>
          )}
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/*<!-- Delete modal -->*/}
      <DeleteModal
        title={"Are you sure you want to delete this ticket type?"}
        isOpen={deletingTicketType}
        onCancel={handleCloseModal}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ViewTicketTypeDetails;
