import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import {
  getUserDetails,
  updateUserDetails,
} from "../../network_calls/user_api";
import DateFormatter from "../../utils/date_formatter";
import { sortAlphabetically } from "../../utils/helper";

const genderDropDownOptions = ["Male", "Female", "Other"];

const EditMyProfileDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchUserData();
  }, [id]);

  const fetchUserData = async () => {
    try {
      loader(true);
      const response = await getUserDetails(id);
      if (response.code === 200) {
        const userData = response.result;
        formik.setValues({
          ...userData,
          dob: DateFormatter.formatYYYYMMDD(userData.dob) || "",
        });
      } else {
        toast.error("Failed to fetch profile data");
        redirectToProfileDetails();
      }
    } catch (error) {
      toast.error("Failed to fetch profile data");
      redirectToProfileDetails();
    } finally {
      loader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      dob: "",
      gender: "",
      about: "",
      address: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.firstname) {
        errors.firstname = "First name is required";
      }

      if (!values.lastname) {
        errors.lastname = "Last name is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        loader(true);
        const formData = {
          ...values,
          dob: values.dob || null,
          address: values.address || null,
        };
        const response = await updateUserDetails(id, formData);
        if (response.code === 200) {
          toast.success("Profile details updated successfully");
          setTimeout(() => {
            redirectToProfileDetails();
          }, 500);
        } else {
          toast.error("Failed to update profile details");
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong");
      } finally {
        loader(false);
      }
    },
  });

  const redirectToProfileDetails = () => {
    navigate(ROUTES.PROFILE.VIEW);
  };

  return (
    <div>
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Edit Profile</h1>
          <p className="text-sm text-gray-500">
            <span>Masters - </span>
            <span
              onClick={redirectToProfileDetails}
              className="cursor-pointer hover:text-blue-600 transition-colors"
            >
              My Proile
            </span>
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {/* First Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  placeholder="Enter first name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.firstname && formik.errors.firstname && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.firstname}
                  </div>
                )}
              </div>

              {/* Last Name */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  placeholder="Enter last name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.lastname}
                  </div>
                )}
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Date of Birth */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Date of Birth
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Gender */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <div className="relative">
                  <select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Gender</option>
                    {sortAlphabetically(genderDropDownOptions).map(
                      (opt, index) => (
                        <option key={index} value={opt.toLowerCase()}>
                          {opt}
                        </option>
                      )
                    )}
                  </select>
                  <ChevronDown className="absolute right-3 top-3 h-4 w-4 text-gray-500" />
                </div>
              </div>
            </div>

            {/* About */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                About
              </label>
              <textarea
                name="about"
                placeholder="Tell us about yourself..."
                value={formik.values.about}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Address */}
            <div className="space-y-2 mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <textarea
                name="address"
                placeholder="Enter your address..."
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={4}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="mt-10 w-80 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {formik.isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditMyProfileDetails;
