import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Search, Download, X, Filter, Plus } from "lucide-react";
import TablePagination from "../../components/table_pagination";
import { useFormik } from "formik";
import ROUTES from "../../config/routes";
import loader from "../../components/loader";
import { toast } from "react-toastify";
import {
  getAllEventCategories,
  getAllEvents,
  getAllArtists,
} from "../../network_calls/event_api";
import { getAllOrganizations } from "../../network_calls/organization_api";
import FilterActionsFooter from "../../components/filter_actions_footer";
import { sortAlphabetically } from "../../utils/helper";
import useAuthData from "../../hooks/use_auth_data";
import { ADMIN } from "../../utils/constants";
import EventTable from "../../components/event_table";

const EventList = () => {
  const navigate = useNavigate();
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [eventListResult, setEventListResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [artists, setArtists] = useState([]);
  const [loginResult] = useAuthData();
  const [filters, setFilters] = useState({
    event_status: "",
    is_published: "",
    event_category_id: "",
    organization_id: "",
    artist_id: "",
    city: "",
    state: "",
    duration_type: "",
    event_type: "",
    sdate: "",
    edate: "",
    sort_by: "",
    sort_order: "desc",
  });
  const menuRef = useRef(null);

  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    fetch("/assets/json/state_city.json")
      .then((response) => response.json())
      .then((data) => {
        setStateData(data.states);
      })
      .catch((error) => {
        console.error("Error loading state data:", error);
      });
  }, []);

  useEffect(() => {
    if (loginResult?.role !== ADMIN) {
      filters.organization_id = loginResult?.organization_id;
    }
  }, [loginResult]);

  useEffect(() => {
    if (selectedState) {
      const stateInfo = stateData.find((state) => state.name === selectedState);
      setCities(stateInfo ? stateInfo.cities : []);
      setSelectedCity("");
    }
  }, [selectedState, stateData]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    formik.setFieldValue("state", e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    formik.setFieldValue("city", e.target.value);
  };

  const handleFilter = async () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      loader(true);

      // Make all API calls but handle each response individually
      const artistPromise = getAllArtists().catch((err) => ({
        code: err.code || 500,
      }));
      const orgsPromise = getAllOrganizations().catch((err) => ({
        code: err.code || 500,
      }));
      const categoriesPromise = getAllEventCategories().catch((err) => ({
        code: err.code || 500,
      }));

      // Wait for all promises to resolve
      const [artistRes, orgsRes, categoriesRes] = await Promise.all([
        artistPromise,
        orgsPromise,
        categoriesPromise,
      ]);

      // Handle each response individually
      if (artistRes.code === 200) {
        const alphabeticallySortedResult = sortAlphabetically(artistRes.result);
        setArtists(alphabeticallySortedResult);
      }

      if (orgsRes.code === 200) {
        const alphabeticallySortedResult = sortAlphabetically(orgsRes.result);
        setOrganizations(alphabeticallySortedResult);
      }

      if (categoriesRes.code === 200) {
        const alphabeticallySortedResult = sortAlphabetically(
          categoriesRes.result
        );
        setCategories(alphabeticallySortedResult);
      }

      // Check if any non-404 errors occurred
      const errors = [artistRes, orgsRes, categoriesRes].filter(
        (res) => res.code !== 200 && res.code !== 404
      );

      if (errors.length > 0) {
        toast.error("Failed to fetch some required data");
      }
    } catch (error) {
      // This will only catch errors not related to the API responses
      toast.error("An unexpected error occurred");
    } finally {
      loader(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getEventList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, itemsPerPage, searchTerm, filters, loginResult]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleFilterChange = (filterData) => {
    setFilters(filterData);
    setCurrentPage(1); // Reset to first page when filters change
    setSelectedCity("");
    setSelectedState("");
  };
  const handleResetFilters = () => {
    formik.resetForm();
    handleFilterChange({
      event_status: "",
      is_published: "",
      event_category_id: "",
      organization_id: "",
      artist_id: "",
      city: "",
      state: "",
      duration_type: "",
      event_type: "",
      sdate: "",
      edate: "",
      sort_by: "",
      sort_order: "desc",
    });
    handleFilter();
  };

  const formik = useFormik({
    initialValues: {
      event_status: "",
      is_published: "",
      event_category_id: "",
      organization_id: "",
      artist_id: "",
      city: "",
      state: "",
      duration_type: "",
      event_type: "",
      sdate: "",
      edate: "",
      sort_by: "",
      sort_order: "desc",
    },
    validate: (values) => {
      const errors = {};
      return errors;
    },
    onSubmit: (values) => {
      handleFilterChange({
        event_status: values.event_status,
        is_published: values.is_published,
        event_category_id: values.event_category_id,
        organization_id: values.organization_id,
        artist_id: values.artist_id,
        city: values.city,
        state: values.state,
        duration_type: values.duration_type,
        event_type: values.event_type,
        sdate: values.sdate,
        edate: values.edate,
        sort_by: values.sort_by,
        sort_order: values.sort_order,
      });
      handleFilter();
    },
  });

  const getEventList = async () => {
    try {
      loader(true);
      if (loginResult?.role !== ADMIN) {
        filters.organization_id = loginResult?.organization_id;
      }
      const response = await getAllEvents({
        page: currentPage,
        limit: itemsPerPage,
        search: searchTerm,
        event_status: filters.event_status,
        is_published: filters.is_published,
        event_category_id: filters.event_category_id,
        organization_id: filters.organization_id,
        artist_id: filters.artist_id,
        city: filters.city,
        state: filters.state,
        duration_type: filters.duration_type,
        event_type: filters.event_type,
        sdate: filters.sdate,
        edate: filters.edate,
        sort_by: filters.sort_by,
        sort_order: filters.sort_order,
      });
      setTotalCount(response.pagination.total);
      setEventListResult(response.result);
      toast.success("List fetched successfully");
    } catch (error) {
      if (error.code === 404) {
        setTotalCount(0);
        setEventListResult([]);
      } else {
        // Show error toast for other errors
        toast.error(error.message || "Operation failed");
      }
    } finally {
      loader(false);
    }
  };

  const addEvent = () => {
    navigate(ROUTES.EVENTS.CREATE);
  };

  const viewEventDetails = (event) => {
    navigate(ROUTES.EVENTS.VIEW.replace(":id", event.id));
  };

  const editEventDetails = (event) => {
    navigate(ROUTES.EVENTS.EDIT.replace(":id", event.id));
  };

  return (
    <div>
      {/*<!-- Start block -->*/}
      <div className="p-2 bg-gray-50 min-h-screen">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold text-gray-800">Events</h1>
          <p className="text-sm text-gray-500">Event Control - Events</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm">
          <div className="p-4 flex justify-between items-center">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="pl-10 pr-4 py-2 bg-gray-100 rounded-lg w-80 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="flex gap-3">
              <button
                onClick={handleFilter}
                className="px-4 py-2 border border-gray-300 text-gray-600 rounded-lg hover:bg-gray-50 flex items-center gap-2 text-sm"
              >
                <Filter className="w-5 h-5" />
                Filter
              </button>
              <button className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 text-sm">
                <Download className="h-5 w-5" />
                Download
              </button>
              <button
                onClick={() => addEvent()}
                className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
              >
                <Plus className="w-5 h-5" />
                Add Event
              </button>
            </div>
          </div>

          <EventTable eventList={eventListResult}>
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={itemsPerPage}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(newPageSize) => {
                setItemsPerPage(newPageSize);
                setCurrentPage(1);
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          </EventTable>
        </div>
      </div>
      {/*<!-- End block -->*/}

      {/* <!-- Drawer Filter-->*/}
      {isFilterDrawerOpen ? (
        <>
          <div
            className="fixed top-0 left-0 z-50 w-full h-screen bg-black opacity-50"
            onClick={handleFilter}
          />
          <div
            className={`fixed top-0 right-0 z-50 w-full h-screen max-w-sm bg-white flex flex-col transition-transform ${
              isFilterDrawerOpen ? "translate-x-0" : "translate-x-full"
            }`}
            tabIndex="-1"
            aria-labelledby="drawer-label"
          >
            {/* Fixed Header */}
            <div className="px-4 py-3 border-b flex items-center justify-between">
              <h5 className="text-sm font-semibold text-gray-500 uppercase">
                Filter List
              </h5>
              <button
                onClick={handleFilter}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              >
                <X className="w-5 h-5" />
                <span className="sr-only">Close menu</span>
              </button>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col h-full"
            >
              {/* Scrollable Content */}
              <div className="flex-1 overflow-y-auto p-4 mb-28">
                <div className="space-y-4">
                  {/* Status */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Status
                    </label>
                    <select
                      name="event_status"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.event_status}
                    >
                      <option value="">All</option>
                      <option value="upcoming">Upcoming</option>
                      <option value="live">Live</option>
                      <option value="expired">Expired</option>
                    </select>
                  </div>

                  {/* Event Category */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Event Category
                    </label>
                    <select
                      name="event_category_id"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.event_category_id}
                    >
                      <option value="">All Event Categories</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Organization */}
                  {loginResult?.role === ADMIN && (
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Organization
                      </label>
                      <select
                        name="organization_id"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                        onChange={formik.handleChange}
                        value={formik.values.organization_id}
                      >
                        <option value="">All Organizations</option>
                        {organizations.map((organization) => (
                          <option key={organization.id} value={organization.id}>
                            {organization.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {/* Artist */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Artist
                    </label>
                    <select
                      name="artist_id"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.artist_id}
                    >
                      <option value="">All Artists</option>
                      {artists.map((artist) => (
                        <option key={artist.id} value={artist.id}>
                          {artist.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* State Dropdown */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <select
                      name="state"
                      value={selectedState}
                      onChange={handleStateChange}
                      onBlur={formik.handleBlur}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select State</option>
                      {stateData.map((state) => (
                        <option key={state.name} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* City Dropdown */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      City
                    </label>
                    <select
                      name="city"
                      value={selectedCity}
                      onChange={handleCityChange}
                      onBlur={formik.handleBlur}
                      disabled={!selectedState}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-100"
                    >
                      <option value="">Select City</option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Duration Type */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Duration
                    </label>
                    <select
                      name="duration_type"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.duration_type}
                    >
                      <option value="">All Durations</option>
                      <option value="single_day">Single Day</option>
                      <option value="multiple_days">Multiple Days</option>
                    </select>
                  </div>

                  {/* Event Type */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Event Time
                    </label>
                    <select
                      name="event_type"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.event_type}
                    >
                      <option value="">All Times</option>
                      <option value="full_day">Full Day</option>
                      <option value="evening">Evening</option>
                      <option value="night">Night</option>
                    </select>
                  </div>

                  {/* Event Publish */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Publication Status
                    </label>
                    <select
                      name="is_published"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.is_published}
                    >
                      <option value="">All</option>
                      <option value="true">Published</option>
                      <option value="false">Unpublished</option>
                    </select>
                  </div>

                  {/* Event Date Range */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Event Start Date
                    </label>
                    <input
                      type="date"
                      name="sdate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.sdate}
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Event End Date
                    </label>
                    <input
                      type="date"
                      name="edate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.edate}
                    />
                  </div>

                  {/* Sort By */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Sort By
                    </label>
                    <select
                      name="sort_by"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      onChange={formik.handleChange}
                      value={formik.values.sort_by}
                    >
                      <option value="">Default</option>
                      <option value="price">Price</option>
                      <option value="popularity">Popularity</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Fixed Footer */}
              <div className="border-t p-4 bg-white">
                <FilterActionsFooter onReset={handleResetFilters} />
              </div>
            </form>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EventList;
