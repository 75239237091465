import { CircleX } from "lucide-react";

function Modal({ isOpen, onClose, children, uniqueKey }) {
  if (!isOpen) return null;

  // Stops the click event from closing the modal when clicking inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={onClose} // Clicking outside will close the modal
      key={uniqueKey}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg relative w-3/4 max-h-[80%]  h-auto overflow-y-scroll"
        onClick={handleModalClick} // Prevents click inside from propagating
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-3 text-red-400 hover:text-red-600"
        >
          <CircleX size={35} />
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
